import React, { FormEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { AddMoreIcon, BackIcon } from '../../utils/functions/Icons'
import { useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/profile.module.css"
import { FormControlLabel, FormGroup, FormLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select, TextareaAutosize, TextField } from '@mui/material'
import AutocompleteInput from '../../components/common/AutocompleteInput'
import moment from 'moment'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { allergiesReducer, familyRelationReducer, healthProblemsReducer, MedicationsReducer, MedicineReducer, symptomsReducer, VaccinationReducer } from '../../utils/store/profile/ProfileSlice'
import { calculateBMI, handleKeyPress } from '../../utils/functions/common'
import { opdCreateApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import BtnLoader from '../../components/common/BtnLoader'

const OpdAdd = () => {
    const [formState, setFormState] = useState<OPDFormState>(
        {
            fixed: {
                date: '',
                time: '',
                employeeName: '',
                employeeCode: '',
                company: '',
                department: '',
                project: '',
                employeeType: '',
                contactNumber: '',
                dateOfBirth: '',
                age: '',
                gender: '',
            },
            opd: {
                systolic: '',
                diastolic: '',
                diagnosis: '',
                temperature: '',
                bloodSugar: '',
                height: '',
                weight: '',
                bmi: '',
                pulse: '',
                bloodGroup: '',
                symptoms: [],
                history: '',
                allergies: [],
                teleconsultationTaken: '',
                treatment: '',
                doctorNameIfYes: '',
                medicineDetails: [{
                    medicineName: [],
                    quantityDispensed: '',
                }],
                doctorName: '',
                attendingDoctor: '',
            },
            doctorOPD: {
                quantityDispensed: '',
                natureOfComplaint: '',
                familyMedicalHistory: '',
                previousHistory: '',
                physicalTherapies: '',
                lifestyleHabits: '',
                lifestyleRecommendations: '',
                treatmentNotes: '',
                labTests: '',
                followUp: '',
                recommendations: '',
                recentActivities: '',
            },
            restRegister: {
                teamLeader: '',
                intime: '',
                outtime: '',
                pregnancyStatus: '',
                reasonOfRest: '',
                remarks: '',
            },
            incidentRegister: {
                insideCompany: '',
                natureOfAccident: '',
                partOfBodyInjured: '',
                machineInvolved: '',
                severity: '',
                complaintType: '',
                typeOfInjuries: '',
                detailedDescription: '',
                treatmentByDoctor: '',
                referrals: '',
                hospitalNameIfYes: '',
            },
            ambulanceRegister: {
                useOfAmbulance: '',
            },
            // consumptionRegister: {
            //     medicineName1: '',
            //     quantityDispensed1: '',
            //     medicineName2: '',
            //     quantityDispensed2: '',
            // },
        }
    )
    const [btnLoading, setBtnLoading] = useState(false)
    const { master } = useSelector((state: RootState) => state.profile);
    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();
    // add more start
    const handleAddMoreOPDM = () => {
        setFormState((prev: OPDFormState) => {
            const lastItem = prev.opd.medicineDetails[prev.opd.medicineDetails.length - 1];
            if (lastItem.medicineName?.length !== 0 && lastItem.quantityDispensed !== '') {
                return {
                    ...prev,
                    opd: {
                        ...prev.opd,
                        medicineDetails: [...prev.opd.medicineDetails, { medicineName: [], quantityDispensed: '' }],
                    },
                };
            }
            return prev;
        });
    };
    const handleFormChangeOPDM = (index: number, fieldName: keyof OPDState['medicineDetails'][0], value: any) => {
        setFormState((prev: OPDFormState) => {
            const newData = [...prev.opd.medicineDetails];
            newData[index][fieldName] = value;
            return { ...prev, opd: { ...prev.opd, medicineDetails: newData } };
        });
    };
    // add more end

    const handleInputChange: HandleInputChangeType = (e) => {
        const { name, value } = e.target;
        const [groupName, fieldName] = name.split('.');
        setFormState((prevFormState) => {
            const groupKey = groupName as keyof OPDFormState;
            return {
                ...prevFormState,
                [groupKey]: {
                    ...prevFormState[groupKey],
                    [fieldName]: value,
                },
            };
        });
    };
    const handleAutoComplete = (name: any, value: any,) => {
        const [groupName, fieldName] = name.split('.');
        setFormState((prevFormState) => {
            const groupKey = groupName as keyof OPDFormState;
            return {
                ...prevFormState,
                [groupKey]: {
                    ...prevFormState[groupKey],
                    [fieldName]: value,
                },
            };
        });
    };
    const handleSelectChange: HandleSelectChangeType = (e) => {
        const { name, value } = e.target;
        const [groupName, fieldName] = name.split('.');
        setFormState((prevFormState) => {
            const groupKey = groupName as keyof OPDFormState;
            return {
                ...prevFormState,
                [groupKey]: {
                    ...prevFormState[groupKey],
                    [fieldName]: value,
                },
            };
        });
    };
    const handleDateChange: HandleDateChangeType = (value, name) => {
        const [groupName, fieldName] = name.split('.');
        const dateValue = moment(value)?.format('DD-MM-YYYY')
        setFormState((prevFormState) => {
            const groupKey = groupName as keyof OPDFormState;
            return {
                ...prevFormState,
                [groupKey]: {
                    ...prevFormState[groupKey],
                    [fieldName]: dateValue,
                },
            };
        });
    };
    const handleTimeChange: HandleDateChangeType = (value, name) => {
        const [groupName, fieldName] = name.split('.');
        const dateValue = moment(value)?.format('HH:mm')
        setFormState((prevFormState) => {
            const groupKey = groupName as keyof OPDFormState;
            return {
                ...prevFormState,
                [groupKey]: {
                    ...prevFormState[groupKey],
                    [fieldName]: dateValue,
                },
            };
        });
    };

    useEffect(() => {
        dispatch(symptomsReducer({ path: 'symptoms' }));
        dispatch(allergiesReducer({ path: 'allergies' }));
        dispatch(MedicineReducer({ path: 'medicines' }))
    }, [])

    useEffect(() => {
        if (formState?.opd?.weight && formState?.opd?.height) {
            const _bmi = calculateBMI(formState?.opd?.weight, formState?.opd?.height)
            setFormState((prev: OPDFormState) => {
                return { ...prev, opd: { ...prev.opd, bmi: _bmi?.toString() } };
            });
        }
    }, [formState])

    const transformStateToPayload = (state: OPDFormState) => {
        const payload = {
            employeeBasicDetails: {
                date: state?.fixed?.date,
                time: state?.fixed?.time,
                employeeName: state?.fixed?.employeeName,
                employeeCode: state?.fixed?.employeeCode,
                company: state?.fixed?.company,
                department: state?.fixed?.department,
                project: state?.fixed?.project,
                employeeType: state?.fixed?.employeeType,
                contactNumber: state?.fixed?.contactNumber,
                dateOfBirth: state?.fixed?.dateOfBirth,
                age: state?.fixed?.age,
                gender: state?.fixed?.gender,
            },
            opdInfo: {
                systolic: state?.opd?.systolic,
                diastolic: state?.opd?.diastolic,
                temperature: state?.opd?.temperature,
                bloodSugar: state?.opd?.bloodSugar,
                height: state?.opd?.height,
                weight: state?.opd?.weight,
                BMI: state?.opd?.bmi,
                pulse: state?.opd?.pulse,
                bloodGroup: state?.opd?.bloodGroup,
                symptoms: JSON.stringify(state?.opd?.symptoms?.map((item: any) => item?.symptomsName ? item?.symptomsName : item)),
                history: state?.opd?.history,
                allergies: JSON.stringify(state?.opd?.allergies?.map((item: any) => item?.allergiesName ? item?.allergiesName : item)),
                diagnosis: state?.opd?.diagnosis,
                treatment: state?.opd?.treatment,
                teleconsultationTaken: state?.opd?.teleconsultationTaken,
                doctorName: state?.opd?.doctorName,
                attendingDoctor: state?.opd?.attendingDoctor,
            },
            opdInfoDetails: state?.opd?.medicineDetails?.map((medicine) => ({
                medicineName: JSON.stringify(medicine?.medicineName?.map((item: any) => item?.medicineName ? item?.medicineName : item)),
                quantityDispense: medicine?.quantityDispensed,
            })),
            doctorOpd: {
                natureOfComplaint: state?.doctorOPD?.natureOfComplaint,
                familyMedicalHistory: state?.doctorOPD?.familyMedicalHistory,
                previousHistory: state?.doctorOPD?.previousHistory,
                physicalTherapies: state?.doctorOPD?.physicalTherapies,
                lifeStyleHabits: state?.doctorOPD?.lifestyleHabits,
                lifestyleRecommendation: state?.doctorOPD?.lifestyleRecommendations,
                illness: state?.doctorOPD?.recentActivities,
                recommendations: state?.doctorOPD?.recommendations,
                treatmentNotes: state?.doctorOPD?.treatmentNotes,
                labTests: state?.doctorOPD?.labTests,
                followUp: state?.doctorOPD?.followUp,
            },
            doctorOpdDetails: state?.opd?.medicineDetails?.map((medicine) => ({
                medicineName: JSON.stringify(medicine?.medicineName?.map((item: any) => item?.medicineName ? item?.medicineName : item)),
                quantityDispense: medicine?.quantityDispensed,
            })),
            restRegister: {
                systolic: state?.opd?.systolic,
                diastolic: state?.opd?.diastolic,
                pulse: state?.opd?.pulse,
                bloodSugar: state?.opd?.bloodSugar,
                temperature: state?.opd?.temperature,
                teamLeader: state?.restRegister?.teamLeader,
                inTime: state?.restRegister?.intime,
                outTime: state?.restRegister?.outtime,
                pregnancyStatus: state?.restRegister?.pregnancyStatus,
                reasonOfRest: state?.restRegister?.reasonOfRest,
                remarks: state?.restRegister?.remarks,
            },
            incidentRegister: {
                insideCompany: state?.incidentRegister?.insideCompany,
                natureOfAccident: state?.incidentRegister?.natureOfAccident,
                bodyPartInjured: state?.incidentRegister?.partOfBodyInjured,
                machineInvolved: state?.incidentRegister?.machineInvolved,
                severity: state?.incidentRegister?.severity,
                complaintType: state?.incidentRegister?.complaintType,
                typesOfInjuries: state?.incidentRegister?.typeOfInjuries,
                detailedDescription: state?.incidentRegister?.detailedDescription,
                treatmentByDoctor: state?.incidentRegister?.treatmentByDoctor,
                referrals: state?.incidentRegister?.referrals,
                hospitalName: state?.incidentRegister?.hospitalNameIfYes,
            },
            ambulanceRegister: {
                useOfAmbulance: state?.ambulanceRegister?.useOfAmbulance,
            },
            consumptionRegister: state?.opd?.medicineDetails?.map((medicine) => ({
                medicineName: JSON.stringify(medicine?.medicineName?.map((item: any) => item?.medicineName ? item?.medicineName : item)),
                quantityDispense: medicine?.quantityDispensed,
            })),
        };

        return payload;
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const payload = transformStateToPayload(formState);
            setBtnLoading(true)
            const result = await opdCreateApi(payload);
            if (result?.success) {
                toast.success(result?.message)
                setBtnLoading(false)
                setFormState({
                    fixed: {
                        date: '',
                        time: '',
                        employeeName: '',
                        employeeCode: '',
                        company: '',
                        department: '',
                        project: '',
                        employeeType: '',
                        contactNumber: '',
                        dateOfBirth: '',
                        age: '',
                        gender: '',
                    },
                    opd: {
                        systolic: '',
                        diastolic: '',
                        diagnosis: '',
                        temperature: '',
                        bloodSugar: '',
                        height: '',
                        weight: '',
                        bmi: '',
                        pulse: '',
                        bloodGroup: '',
                        symptoms: [],
                        history: '',
                        allergies: [],
                        teleconsultationTaken: '',
                        treatment: '',
                        doctorNameIfYes: '',
                        medicineDetails: [{
                            medicineName: [],
                            quantityDispensed: '',
                        }],
                        doctorName: '',
                        attendingDoctor: '',
                    },
                    doctorOPD: {
                        quantityDispensed: '',
                        natureOfComplaint: '',
                        familyMedicalHistory: '',
                        previousHistory: '',
                        physicalTherapies: '',
                        lifestyleHabits: '',
                        lifestyleRecommendations: '',
                        treatmentNotes: '',
                        labTests: '',
                        followUp: '',
                        recommendations: '',
                        recentActivities: '',
                    },
                    restRegister: {
                        teamLeader: '',
                        intime: '',
                        outtime: '',
                        pregnancyStatus: '',
                        reasonOfRest: '',
                        remarks: '',
                    },
                    incidentRegister: {
                        insideCompany: '',
                        natureOfAccident: '',
                        partOfBodyInjured: '',
                        machineInvolved: '',
                        severity: '',
                        complaintType: '',
                        typeOfInjuries: '',
                        detailedDescription: '',
                        treatmentByDoctor: '',
                        referrals: '',
                        hospitalNameIfYes: '',
                    },
                    ambulanceRegister: {
                        useOfAmbulance: '',
                    },
                })
            }
            else {
                setBtnLoading(false)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }


    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        OPD
                    </h2>
                </div>
                <div className={styles.profileMain}>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Employee Information</h3>
                            </div>
                            <div className="grid grid-cols-4 gap-x-16 gap-y-6 mb-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Date <span className='errorMsg'>*</span> </FormLabel>
                                    <DatePicker
                                        className="custumTextField profileInput"
                                        name="fixed.date"
                                        format="DD-MM-YYYY"
                                        value={formState.fixed.date ? moment(formState.fixed.date, 'DD-MM-YYYY') : null}
                                        onChange={(date) => handleDateChange(moment(date)?.toDate(), 'fixed.date')}
                                        slotProps={{
                                            textField: {
                                                required: true,
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Time <span className='errorMsg'>*</span></FormLabel>
                                    <TimePicker
                                        className="custumTextField profileInput"
                                        name={'fixed.time'}
                                        value={formState?.fixed?.time ? moment(formState?.fixed?.time, 'HH:mm') : null}
                                        onChange={(date) => handleTimeChange(moment(date)?.toDate(), 'fixed.time')}
                                        slotProps={{
                                            textField: {
                                                required: true,
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </div>
                            <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Employee Name <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="fixed.employeeName"
                                        placeholder="Employee Name"
                                        required
                                        value={formState?.fixed?.employeeName}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Employee Code</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="fixed.employeeCode"
                                        placeholder="Employee Code"
                                        value={formState?.fixed?.employeeCode}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Company</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="fixed.company"
                                        placeholder="Company"
                                        value={formState?.fixed?.company}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Department</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="fixed.department"
                                        placeholder="Department"
                                        value={formState?.fixed?.department}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Project <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="fixed.project"
                                        required
                                        placeholder="Project"
                                        value={formState?.fixed?.project}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Employee Type</FormLabel>
                                    <Select
                                        name="fixed.employeeType"
                                        value={formState?.fixed?.employeeType}
                                        onChange={handleSelectChange}
                                        className="custumTextField profileInput w-full"
                                    >
                                        <MenuItem value="Permanent">Permanent</MenuItem>
                                        <MenuItem value="Contractual">Contractual</MenuItem>
                                        <MenuItem value="Third Party">Third Party</MenuItem>
                                        <MenuItem value="Others">Others</MenuItem>
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Contact Number <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="fixed.contactNumber"
                                        placeholder="Contact Number"
                                        required
                                        value={formState?.fixed?.contactNumber}
                                        onChange={handleInputChange}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 10

                                        }}
                                        onKeyPress={handleKeyPress}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Date of Birth<span className='errorMsg'>*</span></FormLabel>
                                    <DatePicker
                                        className="custumTextField profileInput"
                                        name="fixed.dateOfBirth"
                                        format="DD-MM-YYYY"
                                        value={formState?.fixed?.dateOfBirth ? moment(formState?.fixed?.dateOfBirth, 'DD-MM-YYYY') : null}
                                        onChange={(date) => handleDateChange(moment(date)?.toDate(), 'fixed.dateOfBirth')}
                                        slotProps={{
                                            textField: {
                                                required: true,
                                            }
                                        }}
                                        maxDate={moment(new Date(), 'DD-MM-YYYY')}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Age <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="fixed.age"
                                        placeholder="Age"
                                        value={formState?.fixed?.age}
                                        onChange={handleInputChange}
                                        required
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 3
                                        }}
                                        onKeyPress={handleKeyPress}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Gender <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        name="fixed.gender"
                                        value={formState?.fixed?.gender}
                                        onChange={handleSelectChange}
                                        required
                                        className="custumTextField profileInput w-full"
                                    >
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </Select>
                                </FormGroup>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>OPD Information</h3>
                            </div>
                            <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Systolic</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.systolic"
                                        placeholder="Systolic"
                                        value={formState?.opd?.systolic}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Diastolic</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.diastolic"
                                        placeholder="Diastolic"
                                        value={formState?.opd?.diastolic}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Temperature</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.temperature"
                                        placeholder="Temperature"
                                        value={formState?.opd?.temperature}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Blood Sugar</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.bloodSugar"
                                        placeholder="Blood Sugar"
                                        value={formState?.opd?.bloodSugar}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Height</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.height"
                                        placeholder="Height"
                                        value={formState?.opd?.height}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            inputMode: 'numeric',
                                            endAdornment: <InputAdornment position="end">in cm</InputAdornment>,
                                        }}
                                        onKeyPress={handleKeyPress}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Weight</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.weight"
                                        placeholder="Weight"
                                        value={formState?.opd?.weight}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            inputMode: 'numeric',
                                            endAdornment: <InputAdornment position="end">in kg</InputAdornment>,
                                        }}
                                        onKeyPress={handleKeyPress}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">BMI</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.bmi"
                                        placeholder="BMI"
                                        value={formState?.opd?.bmi}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            inputMode: 'numeric',
                                        }}
                                        onKeyPress={handleKeyPress}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Pulse</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.pulse"
                                        placeholder="Pulse"
                                        value={formState?.opd?.pulse}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Blood Group</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.bloodGroup"
                                        placeholder="Blood Group"
                                        value={formState?.opd?.bloodGroup}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Symptoms</FormLabel>
                                    <AutocompleteInput
                                        options={master?.symptoms}
                                        multiple={true}
                                        className="custumTextField profileInput"
                                        value={formState?.opd?.symptoms}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.symptomsName}
                                        getOptionKey={(option) => typeof option === 'string' ? option : option.symptomsName}
                                        createOption={(inputValue: string) => {
                                            return { symptomsName: inputValue };
                                        }}
                                        onSelect={(value) => handleAutoComplete('opd.symptoms', value)}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">History</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="opd.history"
                                        placeholder="History"
                                        value={formState?.opd?.history}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Allergies</FormLabel>
                                    <AutocompleteInput
                                        options={master?.allergies}
                                        multiple={true}
                                        className="custumTextField profileInput"
                                        value={formState?.opd?.allergies}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.allergiesName}
                                        getOptionKey={(option) => typeof option === 'string' ? option : option.allergiesName}
                                        createOption={(inputValue: string) => {
                                            return { allergiesName: inputValue };
                                        }}
                                        onSelect={(value) => handleAutoComplete('opd.allergies', value)}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Diagnosis</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="opd.diagnosis"
                                        placeholder="Diagnosis"
                                        value={formState?.opd?.diagnosis}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Treatment</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="opd.treatment"
                                        placeholder="Treatment"
                                        value={formState?.opd?.treatment}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Teleconsultation Taken</FormLabel>
                                    <RadioGroup
                                        row
                                        name="opd.teleconsultationTaken"
                                        value={formState?.opd?.teleconsultationTaken}
                                        onChange={handleInputChange}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio className='customRadio' />}
                                            label="Yes"
                                            sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none' } }}
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio className='customRadio' />}
                                            label="No"
                                            sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none' } }}
                                        />
                                    </RadioGroup>
                                </FormGroup>
                                {
                                    formState?.opd?.teleconsultationTaken === "Yes" && (
                                        <FormGroup className="customFormGroup">
                                            <FormLabel className="customFormLabel">Doctor Name (if yes) <span className='errorMsg'>*</span></FormLabel>
                                            <TextField
                                                type="text"
                                                className="custumTextField profileInput w-full"
                                                name="opd.doctorName"
                                                placeholder="Doctor Name"
                                                value={formState?.opd?.doctorName}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    )
                                }
                            </div>
                            <div className='flex items-end gap-6 mb-6'>
                                <div className="grid grid-cols-2 gap-x-16 gap-y-6 mt-8 w-full">
                                    {formState?.opd?.medicineDetails?.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <FormGroup className="customFormGroup">
                                                    <FormLabel className="customFormLabel">Medicine Name</FormLabel>
                                                    <AutocompleteInput
                                                        options={master?.medicines}
                                                        multiple={true}
                                                        className="custumTextField profileInput"
                                                        value={item?.medicineName}
                                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.medicineName}
                                                        getOptionKey={(option) => typeof option === 'string' ? option : option.medicineName}
                                                        createOption={(inputValue: string) => {
                                                            return { medicineName: inputValue };
                                                        }}
                                                        onSelect={(value: any) => handleFormChangeOPDM(index, 'medicineName', value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="customFormGroup">
                                                    <FormLabel className="customFormLabel">Quantity dispense</FormLabel>
                                                    <TextField
                                                        type='text'
                                                        className={`custumTextField profileInput w-full`}
                                                        name={`opd.${index}.quantityDispensed`}
                                                        placeholder='Quantity dispense'
                                                        value={item?.quantityDispensed}
                                                        onChange={(e) => handleFormChangeOPDM(index, 'quantityDispensed', e.target.value)}
                                                        inputProps={{
                                                            inputMode: 'numeric',
                                                        }}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </FormGroup>
                                            </React.Fragment>
                                        )
                                    })}

                                </div>
                                <button type='button' className={styles.AddMoreBtn} onClick={handleAddMoreOPDM}>
                                    <AddMoreIcon />
                                </button>
                            </div>
                            <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Attending Doctor</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.attendingDoctor"
                                        placeholder="Attending Doctor"
                                        value={formState?.opd?.attendingDoctor}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Doctor OPD Information</h3>
                            </div>
                            <div className='flex items-end gap-6 mb-6'>
                                <div className="grid grid-cols-2 gap-x-16 gap-y-6 mt-8 w-full">
                                    {formState?.opd?.medicineDetails?.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <FormGroup className="customFormGroup">
                                                    <FormLabel className="customFormLabel">Medicine Name</FormLabel>
                                                    <AutocompleteInput
                                                        options={master?.medicines}
                                                        multiple={true}
                                                        className="custumTextField profileInput"
                                                        value={item?.medicineName}
                                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.medicineName}
                                                        getOptionKey={(option) => typeof option === 'string' ? option : option.medicineName}
                                                        createOption={(inputValue: string) => {
                                                            return { medicineName: inputValue };
                                                        }}
                                                        onSelect={(value: any) => handleFormChangeOPDM(index, 'medicineName', value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="customFormGroup">
                                                    <FormLabel className="customFormLabel">Quantity dispense</FormLabel>
                                                    <TextField
                                                        type='text'
                                                        className={`custumTextField profileInput w-full`}
                                                        name={`opd.${index}.quantityDispensed`}
                                                        placeholder='Quantity dispense'
                                                        value={item?.quantityDispensed}
                                                        onChange={(e) => handleFormChangeOPDM(index, 'quantityDispensed', e.target.value)}
                                                        inputProps={{
                                                            inputMode: 'numeric',
                                                        }}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </FormGroup>
                                            </React.Fragment>
                                        )
                                    })}

                                </div>
                                <button type='button' className={styles.AddMoreBtn} onClick={handleAddMoreOPDM}>
                                    <AddMoreIcon />
                                </button>
                            </div>
                            <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup col-span-4">
                                    <FormLabel className="customFormLabel">Nature of Complaint</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="doctorOPD.natureOfComplaint"
                                        placeholder="Nature of Complaint"
                                        value={formState?.doctorOPD.natureOfComplaint}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Family Medical History</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="doctorOPD.familyMedicalHistory"
                                        placeholder="Family Medical History"
                                        value={formState?.doctorOPD?.familyMedicalHistory}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Previous History</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="doctorOPD.previousHistory"
                                        placeholder="Previous History"
                                        value={formState?.doctorOPD?.previousHistory}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Physical Therapies</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="doctorOPD.physicalTherapies"
                                        placeholder="Physical Therapies"
                                        value={formState?.doctorOPD?.physicalTherapies}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Lifestyle Habits</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="doctorOPD.lifestyleHabits"
                                        placeholder="Lifestyle Habits"
                                        value={formState?.doctorOPD?.lifestyleHabits}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Lifestyle Recommendations</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="doctorOPD.lifestyleRecommendations"
                                        placeholder="Lifestyle Recommendations"
                                        value={formState?.doctorOPD?.lifestyleRecommendations}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Recent activities / Illness</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="doctorOPD.recentActivities"
                                        placeholder="Recent Activities"
                                        value={formState?.doctorOPD?.recentActivities}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Recommendations</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="doctorOPD.recommendations"
                                        placeholder="Recommendations"
                                        value={formState?.doctorOPD?.recommendations}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Treatment Notes</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="doctorOPD.treatmentNotes"
                                        placeholder="Treatment Notes"
                                        value={formState?.doctorOPD?.treatmentNotes}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Lab Tests</FormLabel>
                                    <TextareaAutosize
                                        minRows={2}
                                        className={`custumTextField profileInput w-full`}
                                        name="doctorOPD.labTests"
                                        placeholder="Lab Tests"
                                        value={formState?.doctorOPD?.labTests}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup col-span-2">
                                    <FormLabel className="customFormLabel">Follow Up</FormLabel>
                                    <TextareaAutosize
                                        minRows={2}
                                        className={`custumTextField profileInput w-full`}
                                        name="doctorOPD.followUp"
                                        placeholder="Follow Up"
                                        value={formState?.doctorOPD?.followUp}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Rest Register</h3>
                            </div>
                            <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Systolic</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.systolic"
                                        placeholder="Systolic"
                                        value={formState?.opd?.systolic}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Diastolic</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.diastolic"
                                        placeholder="Diastolic"
                                        value={formState?.opd?.diastolic}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Pulse</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.pulse"
                                        placeholder="Pulse"
                                        value={formState?.opd?.pulse}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Blood Sugar</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.bloodSugar"
                                        placeholder="Blood Sugar"
                                        value={formState.opd.bloodSugar}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Temperature</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="opd.temperature"
                                        placeholder="Temperature"
                                        value={formState?.opd?.temperature}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Team Leader</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="restRegister.teamLeader"
                                        placeholder="Team Leader"
                                        value={formState?.restRegister?.teamLeader}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">In time </FormLabel>
                                    <TimePicker
                                        className="custumTextField profileInput"
                                        name={'restRegister.intime'}
                                        value={formState?.restRegister?.intime ? moment(formState?.restRegister?.intime, 'HH:mm') : null}
                                        onChange={(date) => handleTimeChange(moment(date)?.toDate(), 'restRegister.intime')}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Out time</FormLabel>
                                    <TimePicker
                                        className="custumTextField profileInput"
                                        name={'restRegister.outtime'}
                                        value={formState?.restRegister?.outtime ? moment(formState?.restRegister?.outtime, 'HH:mm') : null}
                                        onChange={(date) => handleTimeChange(moment(date)?.toDate(), 'restRegister.outtime')}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Pregnancy Status</FormLabel>
                                    <Select
                                        name="restRegister.pregnancyStatus"
                                        value={formState?.restRegister?.pregnancyStatus}
                                        onChange={handleSelectChange}
                                        className="custumTextField profileInput w-full"
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Reason of rest</FormLabel>
                                    <TextField
                                        type="text"
                                        className="custumTextField profileInput w-full"
                                        name="restRegister.reasonOfRest"
                                        placeholder="Reason of rest"
                                        value={formState?.restRegister?.reasonOfRest}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-4">
                                    <FormLabel className="customFormLabel">Remarks</FormLabel>
                                    <TextareaAutosize
                                        className="custumTextField profileInput w-full"
                                        name="restRegister.remarks"
                                        placeholder="Remarks"
                                        value={formState?.restRegister?.remarks}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Incident Register</h3>
                            </div>
                            <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Inside Company</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput w-full`}
                                        name="incidentRegister.insideCompany"
                                        placeholder="Inside Company"
                                        value={formState.incidentRegister.insideCompany}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Nature of Accident</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput w-full`}
                                        name="incidentRegister.natureOfAccident"
                                        placeholder="Nature of Accident"
                                        value={formState.incidentRegister.natureOfAccident}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Part of Body Injured</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput w-full`}
                                        name="incidentRegister.partOfBodyInjured"
                                        placeholder="Part of Body Injured"
                                        value={formState.incidentRegister.partOfBodyInjured}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Machine Involved</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput w-full`}
                                        name="incidentRegister.machineInvolved"
                                        placeholder="Machine Involved"
                                        value={formState.incidentRegister.machineInvolved}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Severity</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput w-full`}
                                        name="incidentRegister.severity"
                                        placeholder="Severity"
                                        value={formState.incidentRegister.severity}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Complaint Type</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput w-full`}
                                        name="incidentRegister.complaintType"
                                        placeholder="Complaint Type"
                                        value={formState.incidentRegister.complaintType}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Type of Injuries</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput w-full`}
                                        name="incidentRegister.typeOfInjuries"
                                        placeholder="Type of Injuries"
                                        value={formState.incidentRegister.typeOfInjuries}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup col-span-4">
                                    <FormLabel className="customFormLabel">Detailed Description</FormLabel>
                                    <TextareaAutosize
                                        className={`custumTextField profileInput w-full`}
                                        name="incidentRegister.detailedDescription"
                                        placeholder="Detailed Description"
                                        value={formState.incidentRegister.detailedDescription}
                                        onChange={handleInputChange}
                                        minRows={2}
                                    />
                                </FormGroup>

                                <FormGroup className="customFormGroup col-span-4">
                                    <FormLabel className="customFormLabel">Treatment by Doctor</FormLabel>
                                    <TextareaAutosize
                                        minRows={2}
                                        className={`custumTextField profileInput w-full`}
                                        name="incidentRegister.treatmentByDoctor"
                                        placeholder="Treatment by Doctor"
                                        value={formState.incidentRegister.treatmentByDoctor}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Referrals</FormLabel>
                                    <Select
                                        name="incidentRegister.referrals"
                                        value={formState?.incidentRegister?.referrals}
                                        onChange={handleSelectChange}
                                        className="custumTextField profileInput w-full"
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormGroup>
                                {
                                    formState?.incidentRegister?.referrals === "Yes" &&
                                    <FormGroup className="customFormGroup">
                                        <FormLabel className="customFormLabel">Hospital Name (if Yes)</FormLabel>
                                        <TextField
                                            type="text"
                                            className={`custumTextField profileInput w-full`}
                                            name="incidentRegister.hospitalNameIfYes"
                                            placeholder="Hospital Name"
                                            value={formState.incidentRegister.hospitalNameIfYes}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                }
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Ambulance Register</h3>
                            </div>
                            <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Use of Ambulance</FormLabel>
                                    <Select
                                        name="ambulanceRegister.useOfAmbulance"
                                        value={formState?.ambulanceRegister?.useOfAmbulance}
                                        onChange={handleSelectChange}
                                        className="custumTextField profileInput w-full"
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormGroup>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Consumption Register</h3>
                            </div>
                            <div className='flex items-end gap-6'>
                                <div className="grid grid-cols-2 gap-x-16 gap-y-6 mt-8 w-full">
                                    {formState?.opd?.medicineDetails?.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <FormGroup className="customFormGroup">
                                                    <FormLabel className="customFormLabel">Medicine Name</FormLabel>
                                                    <AutocompleteInput
                                                        options={master?.medicines}
                                                        multiple={true}
                                                        className="custumTextField profileInput"
                                                        value={item?.medicineName}
                                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.medicineName}
                                                        getOptionKey={(option) => typeof option === 'string' ? option : option.medicineName}
                                                        createOption={(inputValue: string) => {
                                                            return { medicineName: inputValue };
                                                        }}
                                                        onSelect={(value: any) => handleFormChangeOPDM(index, 'medicineName', value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="customFormGroup">
                                                    <FormLabel className="customFormLabel">Quantity dispense</FormLabel>
                                                    <TextField
                                                        type='text'
                                                        className={`custumTextField profileInput w-full`}
                                                        name={`opd.${index}.quantityDispensed`}
                                                        placeholder='Quantity dispense'
                                                        value={item?.quantityDispensed}
                                                        onChange={(e) => handleFormChangeOPDM(index, 'quantityDispensed', e.target.value)}
                                                        inputProps={{
                                                            inputMode: 'numeric',
                                                        }}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </FormGroup>
                                            </React.Fragment>
                                        )
                                    })}

                                </div>
                                <button type='button' className={styles.AddMoreBtn} onClick={handleAddMoreOPDM}>
                                    <AddMoreIcon />
                                </button>
                            </div>
                        </div>
                        <button className='fillButton mt-8'> {btnLoading && <BtnLoader />} Save Details</button>
                    </form>
                </div>
            </div>
        </CustomContainer>
    )
}

export default OpdAdd