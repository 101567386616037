import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { AddMoreIcon, BackIcon } from '../../utils/functions/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/profile.module.css"
import { FormControlLabel, FormGroup, FormLabel, MenuItem, Radio, RadioGroup, Select, TextareaAutosize, TextField } from '@mui/material'
import { addHandoverApi, editHandoverApi, getHandoverDetailsApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import moment from 'moment'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { EquipmentItemsReducer, LaundaryItemsReducer } from '../../utils/store/profile/ProfileSlice'
import BtnLoader from '../../components/common/BtnLoader'
import Loader from '../../components/common/Loader'
interface HFormTypes {
    equipmentName: string;
    equipmentWorking: string;
    remarks: string;
}
interface HOtherStates {
    date: Date | string;
    time: string;
    acceptingHandover: string;
    remarks?: string;
}
const AddHandover = () => {
    const [FormState, setFormState] = useState<HFormTypes[]>([{
        equipmentName: '',
        equipmentWorking: '',
        remarks: '',
    }])
    const [handoverDutyTime, setHandoverDutyTime] = useState('')
    const [otherStates, setOtherStates] = useState<HOtherStates>({
        date: '',
        time: '',
        acceptingHandover: 'No',
        remarks: ''
    })
    const [btnLoading, setBtnLoading] = useState(false)
    const [loading, setLoading] = useState(false);
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const { master } = useSelector((state: RootState) => state.profile)
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dispatch(EquipmentItemsReducer({ path: 'equipmentItems' }))
    }, [])

    const fetchHandOverDetails = async (id: number) => {
        try {
            setLoading(true)
            const result = await getHandoverDetailsApi(id)
            if (result?.success) {
                setLoading(false)
                const handoverData = result?.handovers as HandoverDetailsTypes;
                if (handoverData) {
                    setOtherStates({
                        ...otherStates,
                        date: handoverData?.date || '',
                        time: handoverData?.time,
                        acceptingHandover: handoverData?.acceptingHandover,
                        remarks: handoverData?.remarks || ''
                    })
                    const _Details = handoverData?.details?.map((item) => {
                        return {
                            ...item,
                            equipmentName: item?.equipmentName,
                            equipmentWorking: item?.equipmentWorking,
                            remarks: item?.remarks
                        }
                    })
                    setFormState(_Details)
                }
            }
            else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.response?.data?.message || error.message);

        }
    }
    useEffect(() => {
        if (location?.state?.id) {
            setIsEdit(true)
            fetchHandOverDetails(location?.state?.id);
        }
    }, []);


    const handleDateChange = (value: Date | null, name: string) => {
        if (name === 'date') {
            const DateValue = moment(value)?.format('DD-MM-YYYY');
            setOtherStates({
                ...otherStates,
                date: DateValue,
            });
        } else if (name === 'time') {
            const TimeValue = moment(value)?.format('HH:mm');
            setOtherStates({
                ...otherStates,
                time: TimeValue,
            });
        }
    };
    const handleAddMore = () => {
        setFormState((prev: HFormTypes[]) => {
            if (prev.every((item: HFormTypes) => item.equipmentName !== '' && item.equipmentWorking !== '')) {
                return [...prev, {
                    equipmentName: '',
                    equipmentWorking: '',
                    remarks: '',
                }];
            }
            return prev;
        });
    };
    const handleFormChange = (index: number, fieldName: keyof HFormTypes, value: string | Date) => {
        setFormState((prev) => {
            const newData = [...prev];
            newData[index][fieldName] = value as string;
            return newData;
        });
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setOtherStates({
            ...otherStates,
            [name]: value
        })
    }
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const sendData = {
                handover: {
                    date: otherStates?.date,
                    time: otherStates?.time,
                    handoverDutyTime: otherStates?.acceptingHandover === 'Yes' ? handoverDutyTime : '',
                    acceptingHandover: otherStates?.acceptingHandover,
                    handoverBy: LoginData?.name,
                    companyId: LoginData?.companyId,
                },
                handoverDetails: FormState,
            };
            const editSendData = {
                handoverId: location?.state?.id,
                handoverDutyTime: otherStates?.acceptingHandover === 'Yes' ? handoverDutyTime : '',
                acceptingHandover: otherStates?.acceptingHandover,
                takenBy: LoginData?.name,
                remarks: otherStates?.remarks,
                details: FormState?.map((item: any) => {
                    return {
                        id: item?.id,
                        remarks: item?.remarks
                    }
                })
            }
            setBtnLoading(true)
            const result = isEdit ? await editHandoverApi(editSendData) : await addHandoverApi(sendData);
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                navigate('/handover')
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');
            const currentTime = `${hours}:${minutes}:${seconds}`;
            setHandoverDutyTime(currentTime)
        };
        updateTime();
        const intervalId = setInterval(updateTime, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        Handover
                    </h2>
                </div>
                <div className={styles.profileMain}>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-3 gap-x-16 gap-y-6 mt-8">
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Date <span className='errorMsgInput'>*</span> </FormLabel>
                                <DatePicker
                                    className="custumTextField profileInput"
                                    name={'date'}
                                    minDate={moment(new Date(), 'DD-MM-YYYY')}
                                    format="DD-MM-YYYY"
                                    disabled={isEdit}
                                    value={otherStates?.date ? moment(otherStates?.date, 'DD-MM-YYYY') : null}
                                    onChange={(date) => handleDateChange(moment(date)?.toDate(), 'date')}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Time <span className='errorMsgInput'>*</span> </FormLabel>
                                <TimePicker
                                    className="custumTextField profileInput"
                                    name={'time'}
                                    value={otherStates?.time ? moment(otherStates?.time, 'HH:mm') : null}
                                    onChange={(date) => handleDateChange(moment(date)?.toDate(), 'time')}
                                    disabled={isEdit}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                        </div>
                        <div className='flex items-end gap-6 w-full'>
                            <div className="grid grid-cols-3 gap-x-16 gap-y-6 mt-8 w-full">
                                {FormState?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <FormGroup className="customFormGroup">
                                                <FormLabel className="customFormLabel">Item Name <span className='errorMsg'>*</span></FormLabel>
                                                <Select
                                                    className={`custumTextField profileInput`}
                                                    placeholder='Item Name'
                                                    name='itemsSent'
                                                    required
                                                    disabled={isEdit}
                                                    value={item?.equipmentName}
                                                    onChange={(e) => handleFormChange(index, 'equipmentName', e.target.value)}
                                                >
                                                    {
                                                        master?.equipmentItems?.map((item, index) => {
                                                            return (
                                                                <MenuItem key={index} value={item?.equipmentName}>{item?.equipmentName}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormGroup>
                                            <FormGroup className="customFormGroup">
                                                <FormLabel className="customFormLabel">Working Condition <span className='errorMsg'>*</span></FormLabel>
                                                <RadioGroup
                                                    row
                                                    name={`formDetails.${index}.equipmentWorking`}
                                                    value={item?.equipmentWorking}

                                                    onChange={(e) => handleFormChange(index, 'equipmentWorking', e.target.value)}
                                                >
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio className='customRadio' />}
                                                        label="Yes"
                                                        required
                                                        disabled={isEdit}
                                                        sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none' } }}
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio className='customRadio' />}
                                                        label="No"
                                                        disabled={isEdit}
                                                        required
                                                        sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none' } }}
                                                    />
                                                </RadioGroup>
                                            </FormGroup>
                                            <FormGroup className="customFormGroup col-span-3" >
                                                <FormLabel className="customFormLabel">Remarks</FormLabel>
                                                <TextareaAutosize
                                                    minRows={2}
                                                    className={`custumTextField profileInput`}
                                                    name={`formDetails.${index}.remarks`}
                                                    placeholder='Remarks'
                                                    value={item?.remarks}
                                                    disabled={isEdit}
                                                    onChange={(e) => handleFormChange(index, 'remarks', e.target.value)}
                                                />
                                            </FormGroup>
                                        </React.Fragment>
                                    )
                                })}

                            </div>
                            <button type='button' disabled={isEdit} className={styles.AddMoreBtn} onClick={handleAddMore}>
                                <AddMoreIcon />
                            </button>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Handover Duties at {handoverDutyTime}</h3>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Are you accepting the handover?</FormLabel>
                                    <RadioGroup
                                        row
                                        name={'acceptingHandover'}
                                        value={otherStates?.acceptingHandover}
                                        onChange={handleInputChange}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio className='customRadio' />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio className='customRadio' />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                </FormGroup>
                                {
                                    isEdit &&
                                    <FormGroup className="customFormGroup col-span-3" >
                                        <FormLabel className="customFormLabel">Remarks</FormLabel>
                                        <TextareaAutosize
                                            minRows={2}
                                            className={`custumTextField profileInput`}
                                            name={'remarks'}
                                            value={otherStates?.remarks}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                }
                            </div>
                        </div>

                        <button type='submit' className='fillButton mt-8'> {btnLoading && <BtnLoader />} Save Details</button>
                    </form>
                </div>
            </div>
        </CustomContainer>
    )
}

export default AddHandover