import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetCityApi,
  GetMasterApi,
  GetStateApi,
} from "../../api/services";

interface State {
  openModal: boolean;
  master: masterTypes;
  loading: boolean;
  stateData: StateType[];
  cityData: CityType[];
}

export const ReportingChecklistReducer = createAsyncThunk<
  State["master"]["reportingChecklist"],
  { path: string }
>("profile/ReportingChecklistReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["reportingChecklist"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const TrainingTopicsReducer = createAsyncThunk<
  State["master"]["trainingTopics"],
  { path: string }
>("profile/TrainingTopicsReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["trainingTopics"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const LaundaryItemsReducer = createAsyncThunk<
  State["master"]["laundaryItems"],
  { path: string }
>("profile/LaundaryItemsReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["laundaryItems"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const EquipmentItemsReducer = createAsyncThunk<
  State["master"]["equipmentItems"],
  { path: string }
>("profile/EquipmentItemsReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["equipmentItems"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const firstAidItemsReducer = createAsyncThunk<
  State["master"]["firstAidItems"],
  { path: string }
>("profile/firstAidItemsReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["firstAidItems"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const MedicineReducer = createAsyncThunk<
  State["master"]["medicines"],
  { path: string }
>("profile/MedicineReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["medicines"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const VaccinationReducer = createAsyncThunk<
  State["master"]["vaccinations"],
  { path: string }
>("profile/VaccinationReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["vaccinations"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const MedicationsReducer = createAsyncThunk<
  State["master"]["medications"],
  { path: string }
>("profile/MedicationsReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["medications"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const healthProblemsReducer = createAsyncThunk<
  State["master"]["healthProblems"],
  { path: string }
>("profile/healthProblemsReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["healthProblems"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const symptomsReducer = createAsyncThunk<
  State["master"]["symptoms"],
  { path: string }
>("profile/symptomsReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["symptoms"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const allergiesReducer = createAsyncThunk<
  State["master"]["allergies"],
  { path: string }
>("profile/allergiesReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["allergies"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const familyRelationReducer = createAsyncThunk<
  State["master"]["familyRelation"],
  { path: string }
>("profile/familyRelationReducer", async (data, thunkAPI) => {
  try {
    const result = await GetMasterApi(data);
    if (result?.success) {
      return result?.data as State["master"]["familyRelation"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const getStateReducer = createAsyncThunk<State["stateData"]>(
  "profile/getStateReducer",
  async (data, thunkAPI) => {
    try {
      const result = await GetStateApi();
      if (result?.success) {
        return result?.states as State["stateData"];
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCiyReducer = createAsyncThunk<
  State["cityData"],
  { stateCode: string }
>("profile/getCiyReducer", async (data, thunkAPI) => {
  try {
    const result = await GetCityApi(data);
    if (result?.success) {
      return result?.cities as State["cityData"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: State = {
  openModal: false,
  master: {
    vaccinations: [],
    medications: [],
    healthProblems: [],
    symptoms: [],
    allergies: [],
    familyRelation: [],
    reportingChecklist: [],
    trainingTopics: [],
    laundaryItems: [],
    equipmentItems: [],
    medicines: [],
    firstAidItems: []
  },
  loading: false,
  stateData: [],
  cityData: [],
};

const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    handleOpenModal: (state) => {
      state.openModal = true;
    },
    handleCloseModal: (state) => {
      state.openModal = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      VaccinationReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["vaccinations"] | undefined>
      ) => {
        state.master.vaccinations = action.payload ?? [];
      }
    );
    builder.addCase(
      MedicationsReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["medications"] | undefined>
      ) => {
        state.master.medications = action.payload ?? [];
      }
    );
    builder.addCase(
      healthProblemsReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["healthProblems"] | undefined>
      ) => {
        state.master.healthProblems = action.payload ?? [];
      }
    );
    builder.addCase(
      symptomsReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["symptoms"] | undefined>
      ) => {
        state.master.symptoms = action.payload ?? [];
      }
    );
    builder.addCase(
      allergiesReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["allergies"] | undefined>
      ) => {
        state.master.allergies = action.payload ?? [];
      }
    );
    builder.addCase(
      familyRelationReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["familyRelation"] | undefined>
      ) => {
        state.master.familyRelation = action.payload ?? [];
      }
    );
    builder.addCase(
      ReportingChecklistReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["reportingChecklist"] | undefined>
      ) => {
        state.master.reportingChecklist = action.payload ?? [];
      }
    );
    builder.addCase(
      TrainingTopicsReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["trainingTopics"] | undefined>
      ) => {
        state.master.trainingTopics = action.payload ?? [];
      }
    );
    builder.addCase(
      LaundaryItemsReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["laundaryItems"] | undefined>
      ) => {
        state.master.laundaryItems = action.payload ?? [];
      }
    );
    builder.addCase(
      EquipmentItemsReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["equipmentItems"] | undefined>
      ) => {
        state.master.equipmentItems = action.payload ?? [];
      }
    );
    builder.addCase(
      firstAidItemsReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["firstAidItems"] | undefined>
      ) => {
        state.master.firstAidItems = action.payload ?? [];
      }
    );
    builder.addCase(
      MedicineReducer.fulfilled,
      (
        state,
        action: PayloadAction<State["master"]["medicines"] | undefined>
      ) => {
        state.master.medicines = action.payload ?? [];
      }
    );
    builder.addCase(
      getStateReducer.pending,
      (state, action: PayloadAction<State["stateData"] | undefined>) => {
        state.stateData = [];
      }
    );
    builder.addCase(
      getStateReducer.fulfilled,
      (state, action: PayloadAction<State["stateData"] | undefined>) => {
        state.stateData = action.payload ?? [];
      }
    );
    builder.addCase(getStateReducer.rejected, (state) => {
      state.stateData = [];
    });
    builder.addCase(getCiyReducer.pending, (state) => {
      state.cityData = [];
    });
    builder.addCase(
      getCiyReducer.fulfilled,
      (state, action: PayloadAction<State["cityData"] | undefined>) => {
        state.cityData = action.payload ?? [];
      }
    );
    builder.addCase(getCiyReducer.rejected, (state) => {
      state.cityData = [];
    });
  },
});

export const {
  handleOpenModal,
  handleCloseModal,
} = ProfileSlice.actions;

export default ProfileSlice;
