import React, { FormEvent, useState } from 'react'
import styles from "../../assets/styles/layout.module.css"
import { FormGroup, FormLabel, IconButton, InputAdornment, TextField } from '@mui/material'
import { HidePasswordIcon, LockIcon, ShowPasswordIcon } from '../../utils/functions/Icons'
import { changePasswordApi, changePasswordInsideApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import BtnLoader from '../../components/common/BtnLoader'
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
import CustomContainer from '../../components/common/CustomContainer'
const Setting = () => {
    const [formState, setFormState] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
    })
    const [btnLoading, setBtnLoading] = useState(false)
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const handleTogglePasswordVisibility = (field: keyof typeof formState) => {
        setFormState({
            ...formState,
            [field]: !formState[field]
        });
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e?.target?.name;
        const value = e?.target?.value;
        setFormState({
            ...formState,
            [name]: value
        })
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (formState?.password !== formState?.confirmPassword) {
                return
            }
            const sendData = {
                email: LoginData?.email,
                newPassword: formState?.password,
            }
            setBtnLoading(true)
            const result = await changePasswordInsideApi(sendData);
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                setFormState({
                    ...formState,
                    password: '',
                    confirmPassword: '',
                    showConfirmPassword: false,
                    showPassword: false
                })
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error((error?.data?.errors?.length !== 0 && error?.data?.errors) || error?.message);

        }
    }
    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2 className='flex items-center gap-4 cursor-pointer'>
                        Setting
                    </h2>
                </div>
                <div className={styles.SettingMain}>
                    <form
                        noValidate={false}
                        className={styles.changePassword}
                        onSubmit={handleSubmit}
                    >
                        <div className="grid grid-cols-1 gap-6">
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Email</FormLabel>
                                <TextField
                                    type="email"
                                    className={`custumTextField profileInput`}
                                    name="email"
                                    placeholder='Email'
                                    value={LoginData?.email}
                                    required
                                    autoComplete='off'
                                    disabled
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Password</FormLabel>
                                <TextField
                                    type={formState.showPassword ? 'text' : 'password'}
                                    className={`custumTextField profileInput`}
                                    name="password"
                                    placeholder='Password'
                                    value={formState.password}
                                    autoComplete="new-password"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => formState?.password && handleTogglePasswordVisibility('showPassword')}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {formState.showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Confirm Password</FormLabel>
                                <TextField
                                    type={formState?.showConfirmPassword ? 'text' : 'password'}
                                    className={`custumTextField profileInput`}
                                    name="confirmPassword"
                                    placeholder='Confirm password'
                                    value={formState.confirmPassword}
                                    required
                                    autoComplete="new-password"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => formState?.confirmPassword && handleTogglePasswordVisibility('showConfirmPassword')}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {formState.showConfirmPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={handleInputChange}
                                />
                                {
                                    formState?.password !== formState?.confirmPassword &&
                                    <span className='errorMsgInput mt-1'>{formState?.password !== formState?.confirmPassword ? "Passwords do not match" : ""}</span>
                                }
                            </FormGroup>
                        </div>
                        <div className='mt-8'>
                            <button type='submit' className='fillButton'>{btnLoading && <BtnLoader />} Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </CustomContainer>
    )
}

export default Setting