import axios from "axios";
import axiosWithAuth from "./axiosInterceptor";
import axiosNoAuth from "./axiosNoAuth";
import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  GET_CITY,
  GET_MASTER,
  GET_STATE,
  LOGIN,
  RESEND_OTP,
  VERIFY_OTP,
  UPLOAD_FILE,
  GET_NOTIFICATION,
  REMOVE_NOTIFICATION,
  GET_MEDICALROOM,
  TRAINING_LIST,
  ADD_TRAINING_LIST,
  BMW_LIST,
  BMW_CREATE,
  LAUNDARY_LIST,
  CREATE_LAUNDRY,
  EQUIPMENT_LIST,
  EQUIPMENT_CREATE,
  HANDOVER_LIST,
  HANDOVER_CREATE,
  HANDOVER_DETAILS,
  HANDOVER_EDIT,
  INVENTORY_LIST,
  INVENTORY_CREATE,
  INVENTORY_EDIT,
  INVENTORY_DETAILS,
  STERLIZATION_LIST,
  STERLIZATION_CREATE,
  AMBULANCE_CHECKLIST,
  AMBULANCE_CHECKLIST_CREATE,
  AMBULANCE_UTILIZATION_LIST,
  AMBULANCE_UTILIZATION_CREATE,
  FIRST_AID_LIST,
  FIRST_AID_CREATE,
  GET_DASHBOARD,
  GET_PROFILE,
  USER_EDIT,
  MEDICAL_ROOMS_DETAILS,
  OPD_CREATE,
  OPD_LIST,
  CHANGE_PASSWORD_INSIDE,
} from "./routes";

interface ApiResponse<T> {
  success: boolean;
  message: string;
  data: T;
  [key: string]: any;
}
const handleApiError = (error: any) => {
  if (error.response) {
    throw {
      status: error.response.status,
      message: error.response.data.message || "An error occurred",
      data: error.response.data,
    };
  } else if (error.request) {
    throw {
      message:
        "No response received from server. Please check your network connection.",
    };
  } else {
    throw {
      message: error.message,
    };
  }
};
export const loginApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(LOGIN, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const forgotPasswordApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(FORGOT_PASSWORD, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const verifyOtpApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(VERIFY_OTP, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const changePasswordApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(CHANGE_PASSWORD, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const changePasswordInsideApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(CHANGE_PASSWORD_INSIDE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const resendOtpApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(RESEND_OTP, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const GetMasterApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(`${GET_MASTER}/${data?.path}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const GetStateApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_STATE);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const GetCityApi = async (data: {
  stateCode: string;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_CITY, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getConnectTokenApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axios.post(
      `https://newapi.akosmd.in/v1/tokens`,
      data
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const uploadFileApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(UPLOAD_FILE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getNotificationApi = async (data: {
  role: string;
  referenceId: number;
  filter: string;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_NOTIFICATION, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const removeNotificationApi = async (data: {
  notificationId: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(REMOVE_NOTIFICATION, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getMedicalRoomApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
      },
    };
    const response = await axiosWithAuth.get(GET_MEDICALROOM, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getEquipmentListApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
      },
    };
    const response = await axiosWithAuth.get(EQUIPMENT_LIST, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addMedicalRoomApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(GET_MEDICALROOM, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addTrainingApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(ADD_TRAINING_LIST, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getTraningApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
      },
    };
    const response = await axiosWithAuth.get(TRAINING_LIST, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getBmwListApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
      },
    };
    const response = await axiosWithAuth.get(BMW_LIST, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getLaundaryListApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
      },
    };
    const response = await axiosWithAuth.get(LAUNDARY_LIST, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getHandoverListApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
      },
    };
    const response = await axiosWithAuth.get(HANDOVER_LIST, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getInventoryListApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
      },
    };
    const response = await axiosWithAuth.get(INVENTORY_LIST, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getAmbulanceUtilizationListApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
      },
    };
    const response = await axiosWithAuth.get(
      AMBULANCE_UTILIZATION_LIST,
      params
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getSterlizationListApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
      },
    };
    const response = await axiosWithAuth.get(STERLIZATION_LIST, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getAmbulanceListApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
      },
    };
    const response = await axiosWithAuth.get(AMBULANCE_CHECKLIST, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getFirstAidListApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
      },
    };
    const response = await axiosWithAuth.get(FIRST_AID_LIST, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getOpdListApi = async (data?: {
  searchQuery?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
  type: string;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        searchQuery: data?.searchQuery ? data?.searchQuery : undefined,
        fromDate: data?.fromDate ? data?.fromDate : undefined,
        toDate: data?.toDate ? data?.toDate : undefined,
        type: data?.type,
      },
    };
    const response = await axiosWithAuth.get(OPD_LIST, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addBmwListApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(BMW_CREATE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addLaundryListApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(CREATE_LAUNDRY, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addEquipmentApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(EQUIPMENT_CREATE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addHandoverApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(HANDOVER_CREATE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addAmbulanceChecklistApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(AMBULANCE_CHECKLIST_CREATE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const editHandoverApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.put(HANDOVER_EDIT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addInventoryApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(INVENTORY_CREATE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const editInventoryApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.put(INVENTORY_EDIT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addSterlizationApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(STERLIZATION_CREATE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addAmbulanceUtilizationApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(
      AMBULANCE_UTILIZATION_CREATE,
      data
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addFirstAidAddApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(FIRST_AID_CREATE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getHandoverDetailsApi = async (
  id: number
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(HANDOVER_DETAILS, {
      params: {
        handoverId: id,
      },
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getInventoryDetailsApi = async (
  id: number
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(INVENTORY_DETAILS, {
      params: {
        inventoryId: id,
      },
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getDashboardApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_DASHBOARD);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getProfileApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_PROFILE);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const updateProfileApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.put(USER_EDIT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const medicalRoomDetailsApi = async (data: {
  staffName: string;
  date: string;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(MEDICAL_ROOMS_DETAILS, {
      params: {
        staffName: data?.staffName,
        date: data?.date,
      },
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const opdCreateApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(OPD_CREATE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
