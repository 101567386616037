import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { jwtDecode } from "jwt-decode";
import moment from 'moment';

export const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
};
export const CustumTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
export const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 3.8,
  slidesToScroll: 3.8,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export function convertUTCtoIST(date: Date): Date {
  let dateIST = new Date(date.getTime());
  const istOffset = 5.5 * 60 * 60 * 1000;
  dateIST = new Date(dateIST.getTime() + istOffset);
  return dateIST;
}

export const decodeToken = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const handleDownloadFile = async (url: string, name?: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
    }
    const blob = await response.blob();
    const objectURL = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objectURL;
    link.download = name || "file";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error while fetching the file:", error);
  }
};
export const calculateAge = (dob:string):number => {
  const birthDate = moment(dob, 'DD-MM-YYYY');
  const currentDate = moment();
  return currentDate.diff(birthDate, 'years');
};


export function getFileExtension(filename: string): string | null {
  if (filename && filename.includes('.')) {
    return filename.split('.').pop()?.toLowerCase() || null;
  }
  return null;
}

export function parseData(value: string | string[]): string[] {
  if (Array.isArray(value)) {
    return value;
  } else {
    return [value];
  }
}

export function calculateBMI(weight: string, height: string): number {
  const weightInKg = parseFloat(weight);
  const heightInCm = parseFloat(height);
  const heightInMeters = heightInCm / 100; // convert height from cm to meters
  const bmi = weightInKg / (heightInMeters * heightInMeters);
  return Number(bmi.toFixed(2)); // round to 2 decimal places
}