import React, { FormEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { AddMoreIcon, BackIcon } from '../../utils/functions/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/profile.module.css"
import { FormGroup, FormLabel, MenuItem, Select, TextareaAutosize, TextField } from '@mui/material'
import AutocompleteInput from '../../components/common/AutocompleteInput'
import { addMedicalRoomApi, medicalRoomDetailsApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers'
import Loader from '../../components/common/Loader'
import { handleKeyPress } from '../../utils/functions/common'

const AddMedicalRooms = () => {
    const [staffName, setStaffName] = useState('');
    const [FormState, setFormState] = useState<FormStateMedicalRoomsType[]>([{
        particularName: '',
        date: null,
        frequency: '',
        quantity: '',
        remarks: '',
    }])
    const [btnLoading, setBtnLoading] = useState(false)
    const [isClone, setIsClone] = useState(false)
    const [isView, setIsView] = useState(false)
    const [loading, setLoading] = useState(false)
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const navigate = useNavigate();
    const location = useLocation();

    const fetchDetailsData = async (data: { staffName: string, date: string }) => {
        try {
            setLoading(true)
            const result = await medicalRoomDetailsApi(data)
            if (result?.success) {
                setLoading(false)
                const _Data = result?.checklistDetails;
                if (_Data) {
                    setStaffName(_Data[0]?.staffName);
                    const _FormData = _Data?.map((item: any) => {
                        return {
                            particularName: item?.particularName,
                            date: item?.date,
                            frequency: item?.frequency,
                            quantity: item?.quantity,
                            remarks: item?.remarks,
                        }
                    })
                    setFormState(_FormData)
                }
            }
            else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    useEffect(() => {
        if (location?.state?.date && location?.state?.staffName && location?.state?.isClone) {
            setIsClone(true)
            fetchDetailsData({ staffName: location?.state?.staffName, date: location?.state?.date })
        }
        if (location?.state?.date && location?.state?.staffName && location?.state?.isView) {
            setIsView(true)
            fetchDetailsData({ staffName: location?.state?.staffName, date: location?.state?.date })
        }
    }, [])


    const handleAddMore = () => {
        setFormState((prev: typeof FormState) => {
            if (prev.every((item) => item.particularName !== '' && item.frequency !== '' && item.date !== null && item.quantity !== '')) {
                return [...prev, {
                    particularName: '',
                    date: null,
                    frequency: '',
                    quantity: '',
                    remarks: '',
                }];
            }
            return prev;
        });
    };
    const handleFormChange = (index: number, fieldName: keyof FormStateMedicalRoomsType, value: string | Date) => {
        setFormState((prev) => {
            const newData = [...prev];
            if (fieldName === 'date') {
                const dateValue = moment(value)?.format('DD-MM-YYYY')
                newData[index][fieldName] = dateValue as string;
            } else {
                newData[index][fieldName] = value as string;
            }
            return newData;
        });
    };


    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {

            const sendData = {
                staffName: staffName,
                ohcId: LoginData?.ohcId,
                companyId: LoginData?.companyId,
                checklistItems: FormState?.map((item) => {
                    return {
                        particularName: item?.particularName,
                        date: item?.date,
                        frequency: item?.frequency,
                        quantity: item?.quantity,
                        remarks: item?.remarks
                    }
                })
            };
            setBtnLoading(true)
            const result = await addMedicalRoomApi(sendData);
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                navigate('/medicalRooms')
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        Medical Room checklist {isView && 'Details'}
                    </h2>
                </div>
                <div className={styles.profileMain}>
                    <form onSubmit={handleSubmit}>
                        <div className='flex items-end gap-6'>
                            <div className="grid grid-cols-4 gap-x-16 gap-y-6 mt-8">
                                {FormState?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <FormGroup className="customFormGroup">
                                                <FormLabel className="customFormLabel">Item Name <span className='errorMsg'>*</span></FormLabel>
                                                <TextField
                                                    type='text'
                                                    className={`custumTextField profileInput w-full`}
                                                    name={`formDetails.${index}.particularName`}
                                                    placeholder='Item Name'
                                                    value={item?.particularName}
                                                    disabled={isClone || isView}
                                                    required
                                                    onChange={(e) => handleFormChange(index, 'particularName', e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup className="customFormGroup">
                                                <FormLabel className="customFormLabel">Frequency <span className='errorMsg'>*</span></FormLabel>
                                                <Select
                                                    className={`custumTextField profileInput`}
                                                    name={`formDetails.${index}.frequency`}
                                                    placeholder='Frequency'
                                                    value={item?.frequency}
                                                    disabled={isClone || isView}
                                                    required
                                                    onChange={(e) => handleFormChange(index, 'frequency', e.target.value)}
                                                >
                                                    <MenuItem value='Daily'>Daily</MenuItem>
                                                    <MenuItem value='Monthly'>Monthly</MenuItem>
                                                    <MenuItem value='Weekly'>Weekly</MenuItem>
                                                    <MenuItem value='Fortnightly'>Fortnightly</MenuItem>
                                                </Select>
                                            </FormGroup>
                                            <FormGroup className="customFormGroup">
                                                <FormLabel className="customFormLabel">Date Of Inspection <span className='errorMsg'>*</span></FormLabel>
                                                <DatePicker
                                                    className="custumTextField profileInput"
                                                    name={`formDetails.${index}.date`}
                                                    value={item?.date ? moment(item?.date, 'DD-MM-YYYY') : null}
                                                    minDate={moment(new Date(), 'DD-MM-YYYY')}
                                                    format="DD-MM-YYYY"
                                                    disabled={isView}
                                                    onChange={(date) => handleFormChange(index, 'date', moment(date)?.toDate())}
                                                    slotProps={{
                                                        textField: {
                                                            required: true,
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup className="customFormGroup">
                                                <FormLabel className="customFormLabel">Quantity/Value <span className='errorMsg'>*</span></FormLabel>
                                                <TextField
                                                    type='text'
                                                    className={`custumTextField profileInput w-full`}
                                                    name={`formDetails.${index}.frequency`}
                                                    placeholder='Quantity/Value'
                                                    value={item?.quantity}
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                    }}
                                                    onKeyPress={handleKeyPress}
                                                    required
                                                    disabled={isView}
                                                    onChange={(e) => handleFormChange(index, 'quantity', e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup className="customFormGroup col-span-4" >
                                                <FormLabel className="customFormLabel">Remarks</FormLabel>
                                                <TextareaAutosize
                                                    minRows={2}
                                                    className={`custumTextField profileInput`}
                                                    name={`formDetails.${index}.remarks`}
                                                    placeholder='Remarks'
                                                    value={item?.remarks}
                                                    disabled={isClone || isView}
                                                    onChange={(e) => handleFormChange(index, 'remarks', e.target.value)}
                                                />
                                            </FormGroup>
                                        </React.Fragment>
                                    )
                                })}

                            </div>
                            {
                                !isView &&
                                <button type='button' disabled={isClone || isView} className={styles.AddMoreBtn} onClick={handleAddMore}>
                                    <AddMoreIcon />
                                </button>
                            }
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Medical Staff on duty</h3>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Medical Staff name <span className='errorMsgInput'>*</span> </FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput w-full`}
                                        name="staffName"
                                        placeholder='Medical Staff name'
                                        required
                                        disabled={isClone || isView}
                                        value={staffName}
                                        onChange={(e) => setStaffName(e.target.value)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        {
                            !isView &&
                            <button className='fillButton mt-8'> {btnLoading && <Loader />} Save Details</button>
                        }
                    </form>
                </div>
            </div>
        </CustomContainer>
    )
}

export default AddMedicalRooms