import React, { FormEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { BackIcon } from '../../utils/functions/Icons'
import { useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/profile.module.css"
import { FormGroup, FormLabel, MenuItem, Select, TextField } from '@mui/material'
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers'
import { toast } from 'react-toastify'
import { addBmwListApi, addLaundryListApi } from '../../utils/api/services'
import BtnLoader from '../../components/common/BtnLoader'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { LaundaryItemsReducer } from '../../utils/store/profile/ProfileSlice'

const AddLaundry = () => {
    const [FormState, setFormState] = useState<AddLaundryTypes>({
        laundarySentBy: '',
        itemsSent: [],
        itemsReceived: [],
        quantitySent: '',
        quantityReceived: '',
        dateSent: null,
        dateReceived: null,
    })
    const [btnLoading, setBtnLoading] = useState(false)
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const {master} = useSelector((state: RootState) => state.profile)
    useEffect(() => {
        dispatch(LaundaryItemsReducer({ path: 'laundaryItems' }))
    }, [])
    const handleInputChange: HandleInputChangeType = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...FormState,
            [name]: value
        })
    };
    const handleDateChange = (value: Date | null, name: string) => {
        const DateValue = moment(value)?.format('DD-MM-YYYY')
        setFormState({
            ...FormState,
            [name]: DateValue
        })
    };

    const handleSelectChange: HandleSelectChangeType = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...FormState,
            [name]: value
        })
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (!FormState?.dateSent || !FormState?.dateReceived) {
                return toast.error('Date is required')
            }
            const sendData = {
                companyId: LoginData?.companyId,
                laundarySentBy: FormState?.laundarySentBy,
                itemsSent: JSON.stringify(FormState?.itemsSent),
                itemsReceived: JSON.stringify(FormState?.itemsReceived),
                quantitySent: FormState?.quantitySent,
                quantityReceived: FormState?.quantityReceived,
                dateSent: FormState?.dateSent,
                dateReceived: FormState?.dateReceived,
            }
            setBtnLoading(true)
            const result = await addLaundryListApi(sendData);
            if (result?.success) {
                toast.success(result?.message)
                setBtnLoading(false)
                navigate('/laundry')
            }
            else {
                setBtnLoading(false)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        Laundry
                    </h2>
                </div>
                <div className={`${styles.profileMain} mt-8`}>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Laundry sent details</h3>
                            </div>
                            <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Laundry sent by <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput w-full`}
                                        name="laundarySentBy"
                                        placeholder='Laundry sent by'
                                        value={FormState?.laundarySentBy}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Items <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Items"
                                        name='itemsSent'
                                        required
                                        multiple
                                        value={FormState?.itemsSent}
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            master?.laundaryItems?.map((item, index) => {
                                                return(
                                                    <MenuItem key={index} value={item?.itemName}>{item?.itemName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Quantity sent <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput w-full`}
                                        name="quantitySent"
                                        placeholder='Quantity sent'
                                        value={FormState?.quantitySent}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Date sent <span className='errorMsg'>*</span></FormLabel>
                                    <DatePicker
                                        className="custumTextField profileInput"
                                        name='dateSent'
                                        value={FormState?.dateSent ? moment(FormState?.dateSent, 'DD-MM-YYYY') : null}
                                        // maxDate={moment(new Date(), 'DD-MM-YYYY')}
                                        format="DD-MM-YYYY"
                                        onChange={(date) => handleDateChange(moment(date)?.toDate(), 'dateSent')}
                                        slotProps={{
                                            textField: {
                                                required: true,
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Laundry received details</h3>
                            </div>
                            <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Items <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Items"
                                        name='itemsReceived'
                                        required
                                        value={FormState?.itemsReceived}
                                        multiple
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            master?.laundaryItems?.map((item, index) => {
                                                return(
                                                    <MenuItem key={index} value={item?.itemName}>{item?.itemName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Quantity received <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput w-full`}
                                        name="quantityReceived"
                                        placeholder='Quantity received'
                                        value={FormState?.quantityReceived}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Date received <span className='errorMsg'>*</span></FormLabel>
                                    <DatePicker
                                        className="custumTextField profileInput"
                                        name='dateReceived'
                                        value={FormState?.dateReceived ? moment(FormState?.dateReceived, 'DD-MM-YYYY') : null}
                                        // maxDate={moment(new Date(), 'DD-MM-YYYY')}
                                        format="DD-MM-YYYY"
                                        onChange={(date) => handleDateChange(moment(date)?.toDate(), 'dateReceived')}
                                        slotProps={{
                                            textField: {
                                                required: true,
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <button className='fillButton mt-8'> {btnLoading && <BtnLoader />} Save Details</button>
                    </form>
                </div>
            </div >
        </CustomContainer >
    )
}

export default AddLaundry