import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { AddMoreIcon, BackIcon } from '../../utils/functions/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/profile.module.css"
import { FormControlLabel, FormGroup, FormLabel, MenuItem, Radio, RadioGroup, Select, TextareaAutosize, TextField } from '@mui/material'
import { addAmbulanceChecklistApi, addHandoverApi, editHandoverApi, getHandoverDetailsApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import moment from 'moment'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { EquipmentItemsReducer, LaundaryItemsReducer } from '../../utils/store/profile/ProfileSlice'
import BtnLoader from '../../components/common/BtnLoader'
import Loader from '../../components/common/Loader'
interface HFormTypes {
    equipmentName: string;
    equipmentWorking: string;
    remarks: string;
    type?: string
}
interface HOtherStates {
    date: Date | string;
}
const AddAmbulanceChecklist = () => {
    const [FormState, setFormState] = useState<HFormTypes[]>([{
        equipmentName: '',
        equipmentWorking: '',
        remarks: '',
        type: 'Ambulance Accessories'
    }])
    const [EquFormState, setEquFormState] = useState<HFormTypes[]>([{
        equipmentName: '',
        equipmentWorking: '',
        remarks: '',
        type: 'Ambulance Equipment'
    }])
    const [otherStates, setOtherStates] = useState<HOtherStates>({
        date: '',
    })
    const [btnLoading, setBtnLoading] = useState(false)
    const [loading, setLoading] = useState(false);
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const { master } = useSelector((state: RootState) => state.profile)
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dispatch(EquipmentItemsReducer({ path: 'equipmentItems' }))
    }, [])


    const handleDateChange = (value: Date | null, name: string) => {
        const DateValue = moment(value)?.format('DD-MM-YYYY');
        setOtherStates({
            ...otherStates,
            date: DateValue,
        });
    };
    const handleAddMore = () => {
        setFormState((prev: HFormTypes[]) => {
            if (prev.every((item: HFormTypes) => item.equipmentName !== '' && item.equipmentWorking !== '')) {
                return [...prev, {
                    equipmentName: '',
                    equipmentWorking: '',
                    remarks: '',
                }];
            }
            return prev;
        });
    };
    const handleEquAddMore = () => {
        setEquFormState((prev: HFormTypes[]) => {
            if (prev.every((item: HFormTypes) => item.equipmentName !== '' && item.equipmentWorking !== '')) {
                return [...prev, {
                    equipmentName: '',
                    equipmentWorking: '',
                    remarks: '',
                }];
            }
            return prev;
        });
    };
    const handleFormChange = (index: number, fieldName: keyof HFormTypes, value: string | Date) => {
        setFormState((prev) => {
            const newData = [...prev];
            newData[index][fieldName] = value as string;
            return newData;
        });
    };

    const handleEquFormChange = (index: number, fieldName: keyof HFormTypes, value: string | Date) => {
        setEquFormState((prev) => {
            const newData = [...prev];
            newData[index][fieldName] = value as string;
            return newData;
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const mergeData = [...FormState, ...EquFormState]
            const sendData = {
                checklistData: {
                    date: otherStates?.date,
                    onDutyEmployee: LoginData?.name,
                },
                detailsData: mergeData,
            };
            setBtnLoading(true)
            const result = await addAmbulanceChecklistApi(sendData);
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                navigate('/ambulance/checklist')
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        Ambulance checklist
                    </h2>
                </div>
                <div className={styles.profileMain}>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-3 gap-x-16 gap-y-6 mt-8">
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">On duty employee</FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="onDutyEmployee"
                                    placeholder='On duty employee'
                                    value={LoginData?.name}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Date <span className='errorMsgInput'>*</span> </FormLabel>
                                <DatePicker
                                    className="custumTextField profileInput"
                                    name={'date'}
                                    minDate={moment(new Date(), 'DD-MM-YYYY')}
                                    format="DD-MM-YYYY"
                                    value={otherStates?.date ? moment(otherStates?.date, 'DD-MM-YYYY') : null}
                                    onChange={(date) => handleDateChange(moment(date)?.toDate(), 'date')}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Ambulance Accessories</h3>
                            </div>
                            <div className='flex items-end gap-6 w-full'>
                                <div className="grid grid-cols-4 gap-x-16 gap-y-6 w-full">
                                    {FormState?.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <FormGroup className="customFormGroup">
                                                    <FormLabel className="customFormLabel">Equipment name <span className='errorMsg'>*</span></FormLabel>
                                                    <Select
                                                        className={`custumTextField profileInput`}
                                                        placeholder='Equipment name'
                                                        name='equipmentName'
                                                        required
                                                        value={item?.equipmentName}
                                                        onChange={(e) => handleFormChange(index, 'equipmentName', e.target.value)}
                                                    >
                                                        {
                                                            master?.equipmentItems?.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item?.equipmentName}>{item?.equipmentName}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </FormGroup>
                                                <FormGroup className="customFormGroup">
                                                    <FormLabel className="customFormLabel">Working Condition <span className='errorMsg'>*</span></FormLabel>
                                                    <RadioGroup
                                                        row
                                                        name={`formDetails.${index}.equipmentWorking`}
                                                        value={item?.equipmentWorking}

                                                        onChange={(e) => handleFormChange(index, 'equipmentWorking', e.target.value)}
                                                    >
                                                        <FormControlLabel
                                                            value="Yes"
                                                            control={<Radio className='customRadio' />}
                                                            label="Yes"
                                                            required
                                                            sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none' } }}
                                                        />
                                                        <FormControlLabel
                                                            value="No"
                                                            control={<Radio className='customRadio' />}
                                                            label="No"
                                                            required
                                                            sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none' } }}
                                                        />
                                                    </RadioGroup>
                                                </FormGroup>
                                                {
                                                    item?.equipmentWorking === 'No' &&
                                                    <FormGroup className="customFormGroup col-span-4" >
                                                        <FormLabel className="customFormLabel">Remarks</FormLabel>
                                                        <TextareaAutosize
                                                            minRows={2}
                                                            className={`custumTextField profileInput`}
                                                            name={`formDetails.${index}.remarks`}
                                                            placeholder='Remarks'
                                                            value={item?.remarks}
                                                            onChange={(e) => handleFormChange(index, 'remarks', e.target.value)}
                                                        />
                                                    </FormGroup>
                                                }
                                            </React.Fragment>
                                        )
                                    })}

                                </div>
                                <button type='button' className={styles.AddMoreBtn} onClick={handleAddMore}>
                                    <AddMoreIcon />
                                </button>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Ambulance Equipment</h3>
                            </div>
                            <div className='flex items-end gap-6 w-full'>
                                <div className="grid grid-cols-4 gap-x-16 gap-y-6 w-full">
                                    {EquFormState?.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <FormGroup className="customFormGroup">
                                                    <FormLabel className="customFormLabel">Equipment name <span className='errorMsg'>*</span></FormLabel>
                                                    <Select
                                                        className={`custumTextField profileInput`}
                                                        placeholder='Equipment name'
                                                        name='equipmentName'
                                                        required
                                                        value={item?.equipmentName}
                                                        onChange={(e) => handleEquFormChange(index, 'equipmentName', e.target.value)}
                                                    >
                                                        {
                                                            master?.equipmentItems?.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item?.equipmentName}>{item?.equipmentName}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </FormGroup>
                                                <FormGroup className="customFormGroup">
                                                    <FormLabel className="customFormLabel">Working Condition <span className='errorMsg'>*</span></FormLabel>
                                                    <RadioGroup
                                                        row
                                                        name={`formDetails.${index}.equipmentWorking`}
                                                        value={item?.equipmentWorking}

                                                        onChange={(e) => handleEquFormChange(index, 'equipmentWorking', e.target.value)}
                                                    >
                                                        <FormControlLabel
                                                            value="Yes"
                                                            control={<Radio className='customRadio' />}
                                                            label="Yes"
                                                            required
                                                            sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none' } }}
                                                        />
                                                        <FormControlLabel
                                                            value="No"
                                                            control={<Radio className='customRadio' />}
                                                            label="No"
                                                            required
                                                            sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none' } }}
                                                        />
                                                    </RadioGroup>
                                                </FormGroup>
                                                {
                                                    item?.equipmentWorking === 'No' &&
                                                    <FormGroup className="customFormGroup col-span-4" >
                                                        <FormLabel className="customFormLabel">Remarks</FormLabel>
                                                        <TextareaAutosize
                                                            minRows={2}
                                                            className={`custumTextField profileInput`}
                                                            name={`formDetails.${index}.remarks`}
                                                            placeholder='Remarks'
                                                            value={item?.remarks}
                                                            onChange={(e) => handleEquFormChange(index, 'remarks', e.target.value)}
                                                        />
                                                    </FormGroup>
                                                }
                                            </React.Fragment>
                                        )
                                    })}

                                </div>
                                <button type='button' className={styles.AddMoreBtn} onClick={handleEquAddMore}>
                                    <AddMoreIcon />
                                </button>
                            </div>
                        </div>
                        <button type='submit' className='fillButton mt-8'> {btnLoading && <BtnLoader />} Save Details</button>
                    </form>
                </div>
            </div>
        </CustomContainer>
    )
}

export default AddAmbulanceChecklist