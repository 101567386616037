import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import CustomContainer from '../../components/common/CustomContainer';
import { BackIcon } from '../../utils/functions/Icons';
import { useNavigate } from 'react-router-dom';
import styles from "../../assets/styles/profile.module.css";
import { FormGroup, FormLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextareaAutosize, TextField } from '@mui/material';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { toast } from 'react-toastify';
import BtnLoader from '../../components/common/BtnLoader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { EquipmentItemsReducer } from '../../utils/store/profile/ProfileSlice';
import NoDataFound from '../../components/common/NoDataFound';
import { addEquipmentApi } from '../../utils/api/services';
import { handleKeyPress } from '../../utils/functions/common';

interface EquipmentDetail {
    equipmentName: string;
    totalQuantity: string;
    availableQuantity: string;
    newlyAdded: string;
    discarded: string;
    remarks: string; 
}

interface FormState {
    onDutyEmployee: string;
    date: Date | null | string;
    equipmentDetails: EquipmentDetail[];
}

const AddEquipment = () => {
    const [formState, setFormState] = useState<FormState>({
        onDutyEmployee: '',
        date: null,
        equipmentDetails: []
    });
    const [btnLoading, setBtnLoading] = useState(false);
    const { LoginData } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { master } = useSelector((state: RootState) => state.profile);

    useEffect(() => {
        dispatch(EquipmentItemsReducer({ path: 'equipmentItems' }));
    }, [dispatch]);

    useEffect(() => {
        if (master?.equipmentItems) {
            const initialDetails = master.equipmentItems.map(item => ({
                equipmentName: item.equipmentName,
                totalQuantity: '',
                availableQuantity: '',
                newlyAdded: '',
                discarded: '',
                remarks: '' 
            }));
            setFormState(prevState => ({
                ...prevState,
                equipmentDetails: initialDetails
            }));
        }
    }, [master]);

    const calculateTotalQuantity = (availableQuantity: string, newlyAdded: string, discarded: string) => {
        const available = parseFloat(availableQuantity) || 0;
        const added = parseFloat(newlyAdded) || 0;
        const discardedValue = parseFloat(discarded) || 0;
        return (available + added - discardedValue).toFixed();
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { name, value } = e.target;
        const updatedDetails = [...formState.equipmentDetails];
        updatedDetails[index] = {
            ...updatedDetails[index],
            [name]: value
        };

        if (name === 'availableQuantity' || name === 'newlyAdded' || name === 'discarded') {
            updatedDetails[index].totalQuantity = calculateTotalQuantity(
                updatedDetails[index].availableQuantity,
                updatedDetails[index].newlyAdded,
                updatedDetails[index].discarded
            );
        }

        if (name === 'discarded') {
            if (parseFloat(value) > 0) {
                updatedDetails[index].remarks = updatedDetails[index].remarks || ''; 
            } else {
                updatedDetails[index].remarks = ''; 
            }
        }

        setFormState({
            ...formState,
            equipmentDetails: updatedDetails
        });
    };

    const handleDateChange = (value: Date | null) => {
        const dateValue = moment(value)?.format('DD-MM-YYYY')
        setFormState({
            ...formState,
            date: dateValue
        });
    };

    const handleRemarksChange = (index: number, value: string) => {
        const updatedDetails = [...formState.equipmentDetails];
        updatedDetails[index] = {
            ...updatedDetails[index],
            remarks: value
        };
        setFormState({
            ...formState,
            equipmentDetails: updatedDetails
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const companyId = LoginData?.companyId;
        const dataToSubmit = {
            equipmentData: {
                onDutyEmployee: formState.onDutyEmployee,
                date: formState.date,
                companyId: companyId
            },
            equipmentDetails: formState.equipmentDetails.map(detail => ({
                equipmentName: detail.equipmentName,
                totalQuantity: detail.totalQuantity,
                availableQuantity: detail.availableQuantity,
                newlyAdded: detail.newlyAdded,
                discarded: detail.discarded,
                discart: detail.remarks
            }))
        };
        setBtnLoading(true);
        try {
            const result = await addEquipmentApi(dataToSubmit);
            if (result?.success) {
                toast.success(result?.message);
                navigate('/equipment');
            } else {
                toast.error(result?.message);
            }
        } catch (error: any) {
            setBtnLoading(false);
            toast.error(error?.response?.data?.message || error.message);
        } finally {
            setBtnLoading(false);
        }
    };

    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        Equipment
                    </h2>
                </div>
                <div className={`${styles.profileMain} mt-8`}>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">On duty employee <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="onDutyEmployee"
                                    placeholder='On duty employee'
                                    value={formState.onDutyEmployee}
                                    onChange={(e) => setFormState({ ...formState, onDutyEmployee: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Date <span className='errorMsg'>*</span></FormLabel>
                                <DatePicker
                                    className="custumTextField profileInput"
                                    name='date'
                                    value={formState?.date ? moment(formState?.date, 'DD-MM-YYYY') : null}
                                    format="DD-MM-YYYY"
                                    onChange={(date) => handleDateChange(moment(date)?.toDate())}

                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                        </div>
                        <div className='mt-8'>
                            <TableContainer component={Paper} className='TableContainer'>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead className='custumTableHead'>
                                        <TableRow>
                                            <TableCell className='whitespace-nowrap'>Sr No.</TableCell>
                                            <TableCell className='whitespace-nowrap'>Equipment Name</TableCell>
                                            <TableCell className='whitespace-nowrap'>Total Quantity</TableCell>
                                            <TableCell className='whitespace-nowrap'>Quantity Available</TableCell>
                                            <TableCell className='whitespace-nowrap'>Newly Added</TableCell>
                                            <TableCell className='whitespace-nowrap'>Discarded</TableCell>
                                            <TableCell className='whitespace-nowrap'></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {master?.equipmentItems?.length > 0 ? (
                                        <TableBody>
                                            {master.equipmentItems.map((item, index) => (
                                                <TableRow
                                                    key={index}
                                                    className='custumTableRow'
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="td" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        {item?.equipmentName}
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        <TextField
                                                            className={`custumTextField ${styles.InputStyle}`}
                                                            name="totalQuantity"
                                                            value={formState.equipmentDetails[index]?.totalQuantity || ''}
                                                            disabled 
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                                readOnly: true 
                                                            }}
                                                            onKeyPress={handleKeyPress}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        <TextField
                                                            type='text'
                                                            className={`custumTextField ${styles.InputStyle}`}
                                                            name="availableQuantity"
                                                            value={formState.equipmentDetails[index]?.availableQuantity || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                            }}
                                                            onKeyPress={handleKeyPress}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        <TextField
                                                            type='text'
                                                            className={`custumTextField ${styles.InputStyle}`}
                                                            name="newlyAdded"
                                                            value={formState.equipmentDetails[index]?.newlyAdded || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                            }}
                                                            onKeyPress={handleKeyPress}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        <TextField
                                                            type='text'
                                                            className={`custumTextField ${styles.InputStyle}`}
                                                            name="discarded"
                                                            value={formState.equipmentDetails[index]?.discarded || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                            }}
                                                            onKeyPress={handleKeyPress}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        {parseFloat(formState.equipmentDetails[index]?.discarded || '0') > 0 && (
                                                            <TextareaAutosize
                                                                className={`custumTextField ${styles.TextAreaStyle}`}
                                                                name="remarks"
                                                                minRows={1}
                                                                value={formState.equipmentDetails[index]?.remarks || ''}
                                                                onChange={(e) => handleRemarksChange(index, e.target.value)}
                                                            />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={7} align="center">
                                                    <NoDataFound />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </div>
                        <button className='fillButton mt-8'> {btnLoading && <BtnLoader />} Save Details</button>
                    </form>
                </div>
            </div>
        </CustomContainer>
    );
};

export default AddEquipment;
