import { DashboardIcon, MedicalRoomIcon, EquipmentIcon, BioMedicalWaistIcon, LaundryIcon, FirstAidIcon, TrainingIcon, OpdIcon, RestIcon, HandOverIcon, InventoryIcon, SterlizationIcon, AmbulanceIcon } from "../../utils/functions/Icons";

const SidebarData: SidebarDataType[] = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: DashboardIcon
    },
    {
        title: 'Medical Rooms',
        path: '/medicalRooms',
        icon: MedicalRoomIcon
    },
    {
        title: 'Training',
        path: '/training',
        icon: TrainingIcon
    },
    {
        title: 'OPD',
        path: '/opd',
        icon: OpdIcon,
    },
    {
        title: 'Bio Med Waste',
        path: '/bioMedWaste',
        icon: BioMedicalWaistIcon,
    },
    {
        title: 'Laundry',
        path: '/laundry',
        icon: LaundryIcon
    },
    {
        title: 'Equipment',
        path: '/equipment',
        icon: EquipmentIcon
    },
    {
        title: 'Handover',
        path: '/handover',
        icon: HandOverIcon
    },
    {
        title: 'Inventory',
        path: '/inventory',
        icon: InventoryIcon
    },
    {
        title: 'Sterlization',
        path: '/sterlization',
        icon: SterlizationIcon
    },
    {
        title: 'Ambulance',
        path: '/ambulance',
        icon: AmbulanceIcon,
        children: [
            { title: 'Checklist', path: '/ambulance/checklist' },
            { title: 'Utilization', path: '/ambulance/utilization' },
        ]
    },
    {
        title: 'First Aid',
        path: '/firstaid',
        icon: FirstAidIcon
    },
];


export default SidebarData;