import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from "../../assets/styles/appointments.module.css"
import { FormGroup, FormLabel, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { SearchIcon } from '../../utils/functions/Icons';
import { ChangeEvent, forwardRef } from 'react';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import DocumentViewModal from '../savedDocuments/DocumentViewModal';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

interface Props {
    searchValue: string,
    loading: boolean,
    MedicalRoomData: ChecklistItem[] | [];
    handleSearchValue: (e: ChangeEvent<HTMLInputElement>) => void;
    handleDateChange: (value: Date | null, name: string) => void;
    filterValue: FilterTypes,
    handleClone: (item: ChecklistItem) => void
    handleView: (item: ChecklistItem) => void
}
const MedicalRoomTable = forwardRef<HTMLTableElement, Props>(({ MedicalRoomData,
    searchValue,
    loading,
    handleSearchValue,
    handleDateChange,
    filterValue,
    handleClone,
    handleView
}, ref) => {
    return (
        <div>
            <div className={'mb-6 flex justify-between items-center w-full max-w-full'}>
                <FormGroup className="customFormGroup w-1/3">
                    <TextField
                        className={`custumTextField ${styles.InputStyle}`}
                        id="searchValue"
                        name="searchValue"
                        value={searchValue}
                        placeholder='Search'
                        onChange={handleSearchValue}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
                <div className='grid grid-cols-2 gap-6 w-1/3'>
                    <FormGroup className="customFormGroup">
                        <FormLabel className="customFormLabel">From</FormLabel>
                        <DatePicker
                            className="custumTextField profileInput ClearBtnDate"
                            name='fromDate'
                            value={moment(filterValue?.fromDate, 'DD-MM-YYYY')}
                            format="DD-MM-YYYY"
                            onChange={(date) => {
                                handleDateChange(date !== null ? moment(date)?.toDate() : null, 'fromDate')
                            }}
                            slotProps={{ field: { clearable: filterValue?.fromDate ? true : false } }}
                        />
                    </FormGroup>
                    <FormGroup className="customFormGroup">
                        <FormLabel className="customFormLabel">To</FormLabel>
                        <DatePicker
                            className="custumTextField profileInput ClearBtnDate"
                            name='toDate'
                            value={moment(filterValue?.toDate, 'DD-MM-YYYY')}
                            format="DD-MM-YYYY"
                            minDate={moment(filterValue?.fromDate, 'DD-MM-YYYY')}
                            onChange={(date) => {
                                handleDateChange(date !== null ? moment(date)?.toDate() : null, 'toDate')
                            }}
                            slotProps={{ field: { clearable: filterValue?.toDate ? true : false } }}
                        />
                    </FormGroup>
                </div>
            </div>
            {
                loading ? <Loader />
                    :
                    <TableContainer component={Paper} className='TableContainer'>
                        <Table ref={ref} sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='custumTableHead'>
                                <TableRow>
                                    <TableCell className='whitespace-nowrap'>Sr No.</TableCell>
                                    <TableCell className='whitespace-nowrap'>Date</TableCell>
                                    <TableCell className='whitespace-nowrap'>Staff Name</TableCell>
                                    <TableCell style={{ maxWidth: '10%', width: '10%', textAlign: 'center' }} className='whitespace-nowrap'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                MedicalRoomData?.length > 0 ?
                                    <TableBody>
                                        {
                                            MedicalRoomData?.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className='custumTableRow'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="td" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="td" scope="row">
                                                            {item?.date}
                                                        </TableCell>
                                                        <TableCell component="td" scope="row">
                                                            {item?.staffName}
                                                        </TableCell>
                                                        <TableCell component="td" scope="row">
                                                            <div className='flex gap-4 items-center'>
                                                                <button className='outLineBtn' onClick={() => handleClone(item)}>Clone</button>
                                                                <button className='fillButton' onClick={() => handleView(item)}>View</button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow
                                            className='custumTableRow'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" colSpan={4}>
                                                <NoDataFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            }
                        </Table>
                    </TableContainer>
            }
            <DocumentViewModal />
        </div>
    );
});

export default MedicalRoomTable