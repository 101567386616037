import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import ScrollToTop from './components/common/ScrollToTop'
import Layout from './components/layout'
import ForgotPassword from './pages/ForgotPassword'
import LoginOtp from './pages/LoginOtp'
import ChangePassword from './pages/ChangePassword'
import Dashboard from './pages/AuthPages/Dashboard'
import ProtectedRoute from './components/common/ProtectedRoute'
import { useSelector } from 'react-redux'
import { RootState } from './utils/store'
import ProfilePage from './pages/AuthPages/Profile'
import Setting from './pages/AuthPages/Setting'
import MedicalRooms from './pages/AuthPages/MedicalRooms'
import AddMedicalRooms from './pages/AuthPages/AddMedicalRooms'
import Training from './pages/AuthPages/Training'
import AddTraining from './pages/AuthPages/AddTraining'
import BioMedWaste from './pages/AuthPages/BioMedWaste'
import AddBMW from './pages/AuthPages/AddBMW'
import Laundry from './pages/AuthPages/Laundry'
import AddLaundry from './pages/AuthPages/AddLaundry'
import Equipment from './pages/AuthPages/Equipment'
import AddEquipment from './pages/AuthPages/AddEquipment'
import Handover from './pages/AuthPages/Handover'
import AddHandover from './pages/AuthPages/AddHandover'
import Inventory from './pages/AuthPages/Inventory'
import AddInventory from './pages/AuthPages/AddInventory'
import Sterlization from './pages/AuthPages/Sterlization'
import AddSterlization from './pages/AuthPages/AddSterlization'
import AmbulanceChecklist from './pages/AuthPages/AmbulanceChecklist'
import AddAmbulanceChecklist from './pages/AuthPages/AddAmbulanceChecklist'
import AmbulanceUtilization from './pages/AuthPages/AmbulanceUtilization'
import AddAmbulanceUtilization from './pages/AuthPages/AddAmbulanceUtilization'
import FirstAid from './pages/AuthPages/FirstAid'
import AddFirstAid from './pages/AuthPages/AddFirstAid'
import OpdPage from './pages/AuthPages/OpdPages'
import OpdAdd from './pages/AuthPages/OpdAdd'

const App = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/otp' element={<LoginOtp />} />
        <Route path='/change-password' element={<ChangePassword />} />
        <Route path="/*"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }>
          <Route path='myaccount/profile' element={<ProfilePage />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='medicalRooms' element={<MedicalRooms />} />
          <Route path='medicalRooms/:id' element={<AddMedicalRooms />} />
          <Route path='opd' element={<OpdPage />} />
          <Route path='opd/add' element={<OpdAdd />} />
          <Route path='training' element={<Training />} />
          <Route path='bioMedWaste' element={<BioMedWaste />} />
          <Route path='bioMedWaste/add' element={<AddBMW />} />
          <Route path='laundry' element={<Laundry />} />
          <Route path='laundry/add' element={<AddLaundry />} />
          <Route path='equipment' element={<Equipment />} />
          <Route path='equipment/add' element={<AddEquipment />} />
          <Route path='handover' element={<Handover />} />
          <Route path='handover/:id' element={<AddHandover />} />
          <Route path='training/add' element={<AddTraining />} />
          <Route path='inventory' element={<Inventory />} />
          <Route path='inventory/:id' element={<AddInventory />} />
          <Route path='inventory/:id' element={<AddInventory />} />
          <Route path='sterlization' element={<Sterlization />} />
          <Route path='sterlization/add' element={<AddSterlization />} />
          <Route path='ambulance/checklist' element={<AmbulanceChecklist />} />
          <Route path='ambulance/checklist/add' element={<AddAmbulanceChecklist />} />
          <Route path='ambulance/utilization' element={<AmbulanceUtilization />} />
          <Route path='firstaid' element={<FirstAid />} />
          <Route path='firstaid/add' element={<AddFirstAid />} />
          <Route path='ambulance/utilization/add' element={<AddAmbulanceUtilization />} />
          <Route path='myaccount/settings' element={<Setting />} />
          <Route path='*' element={<Dashboard />} />
          <Route
            path="/*"
            element={<Navigate to={"/dashboard"} replace />}
          />
        </Route>
        <Route
          path="/*"
          element={<Navigate to={!token ? "/login" : '/'} replace />}
        />
      </Routes>
    </>
  )
}

export default App