import { Box, Modal } from '@mui/material';
import React, { useState } from 'react';
import { CrossIcon } from '../../utils/functions/Icons';
import styles from "../../assets/styles/teams.module.css";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { handleCloseDocument } from '../../utils/store/common/CommonSlice';
import { handleDownloadFile } from '../../utils/functions/common';
import Loader from '../common/Loader';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const DocumentViewModal: React.FC = () => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const { viewDocument, particularPrescription } = useSelector((state: RootState) => state.common)
    const dispatch = useDispatch<AppDispatch>();

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setNumPages(numPages);
    };

    const handleClose = () => {
        dispatch(handleCloseDocument());
        setPageNumber(1)
    }
    const handleDownload = () => {
        if (particularPrescription?.prescriptionURL) {
            handleDownloadFile(particularPrescription?.prescriptionURL, particularPrescription?.fileName)
        }
    }

    return (
        <Modal
            open={viewDocument}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: '80%' }} className={"ModalBoxStyle"}>
                <div className="modalHeader">
                    <div className={styles.viewDocumentHeader}>
                        {
                            particularPrescription?.dateTime &&
                            <h3>Prescribed on - <span>{particularPrescription?.dateTime}</span></h3>
                        }
                        {
                            particularPrescription?.doctorName &&
                            <h3>Prescribed by - <span>{particularPrescription?.doctorName}</span></h3>
                        }
                        {
                            particularPrescription?.speciality &&
                            <h3>Specialisation - <span>{particularPrescription?.speciality}</span></h3>
                        }
                        <button className='fillButton' onClick={handleDownload}>Download PDF</button>
                    </div>
                    <CrossIcon onClick={handleClose} />
                </div>
                <div>
                    {
                        particularPrescription?.prescriptionURL &&
                        <div className={styles.pdfViewerMain}>
                            <Document
                                file={particularPrescription?.prescriptionURL}
                                className={'viewDocumentPdf'}
                                onLoadSuccess={onDocumentLoadSuccess}
                                loading={<Loader />}
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                            <div className='documentPrevNextPage'>
                                <button onClick={() => setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1))}>Previous</button>
                                <p>Page {pageNumber} of {numPages}</p>
                                <button onClick={() => setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages || 1))}>Next</button>
                            </div>
                        </div>
                    }
                </div>
            </Box>
        </Modal>
    );
};

export default DocumentViewModal;
