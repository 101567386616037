import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/labtest.module.css"
import { useDownloadExcel } from 'react-export-table-to-excel';
import { getEquipmentListApi, getLaundaryListApi, getOpdListApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import moment from 'moment'
import EquipmentTable from '../../components/equipment/EquipmentTable';
import { Tab, Tabs } from '@mui/material';
import { a11yProps, CustomTabPanel } from '../../components/common/CustomTabPanel';
import OpdTable from '../../components/opd/OpdTable';
import OpdDoctorTable from '../../components/opd/OpdDoctorTable';
import IncidentRegisterTable from '../../components/opd/IncidentRegisterTable';
import AmbulanceRegisterTable from '../../components/opd/AmbulanceRegisterTable';
import ConsumtionRegisterTable from '../../components/opd/ConsumtionRegisterTable';
import EmployeeDetailsTable from '../../components/opd/EmployeeDetailsTable';
import RestRegisterTable from '../../components/opd/RestRegisterTable';
import * as ExcelJS from 'exceljs';

const OpdPage = () => {
    const [searchValue, setSearchValue] = useState('')
    const [filterValue, setFilterValue] = useState<FilterTypes>({
        fromDate: null,
        toDate: null
    })
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false)
    const [basicDetails, setBasicDetails] = useState<OpdbasicDetailsTypes[] | []>([]);
    const [opdData, setOpdData] = useState<OpdRecordTypes[] | []>([]);
    const [doctorOpdData, setDoctorOpdData] = useState<DoctorOpdRecord[] | []>([]);
    const [restData, setRestData] = useState<RestRecord[] | []>([]);
    const [incidentData, setIncidentData] = useState<IncidentRecord[] | []>([]);
    const [allData, setAllData] = useState([])
    const [consumptionData, setConsumptionData] = useState<ConsumptionRecord[] | []>([]);
    const navigate = useNavigate();


    const tableRef = useRef<HTMLTableElement>(null);

    // export all table 
    const handleExportData = () => {
        const dataToExport = () => {
            switch (value) {
                case 0:
                    return basicDetails;
                case 1:
                    return opdData;
                case 2:
                    return doctorOpdData;
                case 3:
                    return restData;
                case 4:
                    return incidentData;
                case 5:
                    return consumptionData;
                default:
                    return [];
            }
        };

        const tableData = dataToExport();
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('OPD');

        worksheet.addRow(Object.keys(tableData[0]));

        tableData.forEach((row) => {
            worksheet.addRow(Object.values(row));
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'OPD.xlsx';
            a.click();
            URL.revokeObjectURL(url);
        });
    };
    const handleAllExportData = () => {
        const tableData = allData;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('OPD');

        worksheet.addRow(Object.keys(tableData[0]));

        tableData.forEach((row) => {
            worksheet.addRow(Object.values(row));
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'OPD.xlsx';
            a.click();
            URL.revokeObjectURL(url);
        });
    };
    // export all table 

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setSearchValue('');
        setFilterValue({
            fromDate: null,
            toDate: null
        })
    };

    const handleDateChange = (value: Date | null, name: string) => {
        const momentValue = value !== null ? moment(value)?.format('DD-MM-YYYY') : null;
        setFilterValue({
            ...filterValue,
            [name]: momentValue
        })
    }
    const handleSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }


    const fetchOpdData = async (data?: { searchQuery?: string, fromDate?: Date | null, toDate: Date | null, type: string }) => {
        try {
            setLoading(true)
            const result = await getOpdListApi(data);
            if (result?.success) {
                setLoading(false)
                const type = data?.type;
                switch (type) {
                    case 'employeeBasicDetails':
                        setBasicDetails(result?.records);
                        break;
                    case 'opdInfo':
                        setOpdData(result?.records);
                        break;
                    case 'doctorOpd':
                        setDoctorOpdData(result?.records);
                        break;
                    case 'restRegister':
                        setRestData(result?.records);
                        break;
                    case 'incidentRegister':
                        setIncidentData(result?.records);
                        break;
                    case 'consumptionRegister':
                        setConsumptionData(result?.records);
                        break;
                    case 'all':
                        setAllData(result?.records);
                        break;
                    default:
                        break;
                }
            } else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        const type = value === 0 ? 'employeeBasicDetails' : value === 1 ? 'opdInfo' : value === 2 ? 'doctorOpd' : value === 3 ? 'restRegister' : value === 4 ? 'incidentRegister' : 'consumptionRegister'
        if (searchValue) {
            const cleanUpFun = setTimeout(() => {
                fetchOpdData({ searchQuery: searchValue, fromDate: filterValue?.fromDate, toDate: filterValue?.toDate, type })
            }, 1000);
            return () => clearInterval(cleanUpFun)
        }
        else {
            fetchOpdData({ searchQuery: searchValue ? searchValue : undefined, fromDate: filterValue?.fromDate, toDate: filterValue?.toDate, type })
        }

    }, [searchValue, filterValue, value]);

    useEffect(() => {
        if (searchValue) {
            const cleanUpFun = setTimeout(() => {
                fetchOpdData({ searchQuery: searchValue, fromDate: filterValue?.fromDate, toDate: filterValue?.toDate, type: 'all' })
            }, 1000);
            return () => clearInterval(cleanUpFun)
        }
        else {
            fetchOpdData({ searchQuery: searchValue ? searchValue : undefined, fromDate: filterValue?.fromDate, toDate: filterValue?.toDate, type: 'all' })
        }
    }, [searchValue, filterValue, value]);



    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>OPD</h2>
                    <div className='flex items-center gap-6'>
                        <button className='fillButton' onClick={() => navigate('/opd/add')}>New Entry +</button>
                        <button className='outLineBtn' onClick={handleExportData}>Export Data</button>
                        <button className='outLineBtn' onClick={handleAllExportData}>Export All Data</button>
                    </div>
                </div>
                <div className={styles.labTestMain}>

                    <Tabs value={value} onChange={handleChange} className="custumTabs">
                        <Tab label="Employee details" {...a11yProps(0)} />
                        <Tab label="OPD" {...a11yProps(1)} />
                        <Tab label="Doctor OPD" {...a11yProps(2)} />
                        <Tab label="Rest Register" {...a11yProps(3)} />
                        <Tab label="Incident Register" {...a11yProps(4)} />
                        <Tab label="Consumption Regsiter" {...a11yProps(5)} />
                    </Tabs>
                    <CustomTabPanel value={value} index={0} noPaddingBottom={true}>
                        <EmployeeDetailsTable
                            listData={basicDetails}
                            loading={loading}
                            searchValue={searchValue}
                            ref={tableRef}
                            handleSearchValue={handleSearchValue}
                            filterValue={filterValue}
                            handleDateChange={handleDateChange}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1} noPaddingBottom={true}>
                        <OpdTable
                            listData={opdData}
                            loading={loading}
                            searchValue={searchValue}
                            ref={tableRef}
                            handleSearchValue={handleSearchValue}
                            filterValue={filterValue}
                            handleDateChange={handleDateChange}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2} noPaddingBottom={true}>
                        <OpdDoctorTable
                            listData={doctorOpdData}
                            loading={loading}
                            searchValue={searchValue}
                            ref={tableRef}
                            handleSearchValue={handleSearchValue}
                            filterValue={filterValue}
                            handleDateChange={handleDateChange}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3} noPaddingBottom={true}>
                        <RestRegisterTable
                            listData={restData}
                            loading={loading}
                            searchValue={searchValue}
                            ref={tableRef}
                            handleSearchValue={handleSearchValue}
                            filterValue={filterValue}
                            handleDateChange={handleDateChange}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4} noPaddingBottom={true}>
                        <IncidentRegisterTable
                            listData={incidentData}
                            loading={loading}
                            searchValue={searchValue}
                            ref={tableRef}
                            handleSearchValue={handleSearchValue}
                            filterValue={filterValue}
                            handleDateChange={handleDateChange}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={5} noPaddingBottom={true}>
                        <ConsumtionRegisterTable
                            listData={consumptionData}
                            loading={loading}
                            searchValue={searchValue}
                            ref={tableRef}
                            handleSearchValue={handleSearchValue}
                            filterValue={filterValue}
                            handleDateChange={handleDateChange}
                        />
                    </CustomTabPanel>
                </div>
            </div>
        </CustomContainer>
    )
}

export default OpdPage