import React, { FormEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { AddMoreIcon, BackIcon } from '../../utils/functions/Icons'
import { useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/profile.module.css"
import { FormGroup, FormLabel, MenuItem, Select, TextareaAutosize, TextField } from '@mui/material'
import AutocompleteInput from '../../components/common/AutocompleteInput'
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers'
import { toast } from 'react-toastify'
import { addTrainingApi } from '../../utils/api/services'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { getCiyReducer, getStateReducer, ReportingChecklistReducer, TrainingTopicsReducer } from '../../utils/store/profile/ProfileSlice'
import { handleKeyPress } from '../../utils/functions/common'
import BtnLoader from '../../components/common/BtnLoader'

const AddTraining = () => {
    const [FormState, setFormState] = useState<FormStateTrainingType>({
        companyDetails: {
            name: '',
            state: '',
            city: '',
            officeLocation: ''
        },
        reportingdetails: {
            date: '',
            reportingManager: '',
            checklist: '',
        },
        trainingDetails: {
            name: '',
            noParticipants: '',
            trainingTopics: '',
            remarks: ''
        },
    })
    const [btnLoading, setBtnLoading] = useState(false)
    const [attendees, setAttendees] = useState<attendeesDetailsForm[] | []>([
        {
            name: '',
            id: '',
        },
    ]);
    const dispatch = useDispatch<AppDispatch>();
    const { stateData, cityData, master } = useSelector((state: RootState) => state.profile);
    const { LoginData } = useSelector((state: RootState) => state.auth)
    useEffect(() => {
        dispatch(getStateReducer())
        dispatch(ReportingChecklistReducer({ path: 'reportingChecklist' }))
        dispatch(TrainingTopicsReducer({ path: 'trainingTopics' }))
    }, []);

    const handleAddMore = () => {
        setAttendees((prevAttendees: typeof attendees) => {
            if (prevAttendees.every((attendee) => attendee.name !== '' && attendee.id !== '')) {
                return [...prevAttendees, { name: '', id: '' }];
            }
            return prevAttendees;
        });
    };

    const handleAttendeeChange = (index: number, fieldName: keyof typeof attendees[0], value: string) => {
        setAttendees((prevAttendees) => {
            const newAttendees = [...prevAttendees];
            newAttendees[index][fieldName] = value;
            return newAttendees;
        });
    };
    const navigate = useNavigate();

    const handleInputChange: HandleInputChangeType = (e) => {
        const { name, value } = e.target;
        const [groupName, fieldName] = name.split('.');
        setFormState((prevFormState) => {
            const groupKey = groupName as keyof FormStateTrainingType;
            return {
                ...prevFormState,
                [groupKey]: {
                    ...prevFormState[groupKey],
                    [fieldName]: value,
                },
            };
        });
    };
    const handleSelectChange: HandleSelectChangeType = (e) => {
        const { name, value } = e.target;
        const [groupName, fieldName] = name.split('.');
        setFormState((prevFormState) => {
            const groupKey = groupName as keyof FormStateTrainingType;
            return {
                ...prevFormState,
                [groupKey]: {
                    ...prevFormState[groupKey],
                    [fieldName]: value,
                },
            };
        });
        if (name === 'companyDetails.state') {
            const stateCode = stateData?.filter((item) => item?.state === value)
            if (stateCode?.length > 0) {
                dispatch(getCiyReducer({ stateCode: stateCode[0]?.code }))
            }
        }
    };
    const handleDateChange: HandleDateChangeType = (value, name) => {
        const [groupName, fieldName] = name.split('.');
        const dateValue = moment(value)?.format('DD-MM-YYYY')
        setFormState((prevFormState) => {
            const groupKey = groupName as keyof FormStateTrainingType;
            return {
                ...prevFormState,
                [groupKey]: {
                    ...prevFormState[groupKey],
                    [fieldName]: dateValue,
                },
            };
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const sendData = {
                trainingData: {
                    companyId: LoginData?.companyId,
                    companyName: FormState?.companyDetails?.name,
                    city: FormState?.companyDetails?.city,
                    state: FormState?.companyDetails?.state,
                    location: FormState?.companyDetails?.officeLocation,
                    dateOfTraining: FormState?.reportingdetails?.date,
                    reportingManager: FormState?.reportingdetails?.reportingManager,
                    trainerName: FormState?.trainingDetails?.name,
                    noOfParticipants: FormState?.trainingDetails?.noParticipants,
                    trainingTopics: FormState?.trainingDetails?.trainingTopics,
                    remarks: FormState?.trainingDetails?.remarks,
                },
                employeeAttendees: attendees?.map((item) => {
                    return {
                        employeeName: item?.name,
                        employeeId: item?.id
                    }
                })
            }
            setBtnLoading(true)
            const result = await addTrainingApi(sendData);
            if (result?.success) {
                toast.success(result?.message)
                setBtnLoading(false)
                navigate('/training')
            }
            else {
                setBtnLoading(false)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        Training
                    </h2>
                </div>
                <div className={styles.profileMain}>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Company Details</h3>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Company Name <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput w-full`}
                                        name="companyDetails.name"
                                        placeholder='Company Name'
                                        value={FormState?.companyDetails?.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">State <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        name="companyDetails.state"
                                        placeholder='State'
                                        required
                                        value={FormState?.companyDetails?.state}
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            stateData?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item?.state}>{item?.state}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">City <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        name="companyDetails.city"
                                        placeholder='City'
                                        disabled={FormState?.companyDetails?.state === ''}
                                        required
                                        value={FormState?.companyDetails?.city}
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            cityData?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item?.city}>{item?.city}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Office Location <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput w-full`}
                                        name="companyDetails.officeLocation"
                                        placeholder='Office Location'
                                        value={FormState?.companyDetails?.officeLocation}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Reporting details</h3>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Date of training <span className='errorMsg'>*</span></FormLabel>
                                    <DatePicker
                                        className="custumTextField profileInput"
                                        name='reportingdetails.date'
                                        value={FormState?.reportingdetails?.date ? moment(FormState?.reportingdetails?.date, 'DD-MM-YYYY') : null}
                                        minDate={moment(new Date(), 'DD-MM-YYYY')}
                                        format="DD-MM-YYYY"
                                        onChange={(date) => handleDateChange(moment(date)?.toDate(), 'reportingdetails.date')}
                                        slotProps={{
                                            textField: {
                                                required: true,
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Reporting Manager <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput w-full`}
                                        name="reportingdetails.reportingManager"
                                        placeholder='Reporting Manager'
                                        value={FormState?.reportingdetails?.reportingManager}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-3" >
                                    <FormLabel className="customFormLabel">Reporting Checklist <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Reporting Checklist"
                                        name='reportingdetails.checklist'
                                        value={FormState?.reportingdetails?.checklist}
                                        onChange={handleSelectChange}
                                        required
                                    >
                                        {
                                            master?.reportingChecklist?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item?.checklistName}>{item?.checklistName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Training Details</h3>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 gap-y-6">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Trainer Name <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput w-full`}
                                        name="trainingDetails.name"
                                        placeholder='Trainer Name'
                                        value={FormState?.trainingDetails?.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">No. of participants <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput w-full`}
                                        name="trainingDetails.noParticipants"
                                        placeholder='No. of participants'
                                        value={FormState?.trainingDetails?.noParticipants}
                                        onChange={handleInputChange}
                                        required
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                        onKeyPress={handleKeyPress}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-3">
                                    <FormLabel className="customFormLabel">Training Topics <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Training Topics"
                                        name='trainingDetails.trainingTopics'
                                        value={FormState?.trainingDetails?.trainingTopics}
                                        onChange={handleSelectChange}
                                        required
                                    >
                                        {
                                            master?.trainingTopics?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item?.trainingName}>{item?.trainingName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup col-span-3">
                                    <FormLabel className="customFormLabel">Remarks </FormLabel>
                                    <TextareaAutosize
                                        minRows={2}
                                        className={`custumTextField profileInput`}
                                        name='trainingDetails.remarks'
                                        placeholder='Remarks'
                                        value={FormState?.trainingDetails?.remarks}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Attendees Details</h3>
                            </div>
                            <div className='flex items-end gap-6'>
                                <div className="grid grid-cols-2 gap-x-16 gap-y-6 w-full">
                                    {attendees.map((attendee, index) => (
                                        <React.Fragment key={index} >
                                            <FormGroup className="customFormGroup">
                                                <FormLabel className="customFormLabel">Employee Name <span className='errorMsg'>*</span></FormLabel>
                                                <TextField
                                                    type='text'
                                                    className={`custumTextField profileInput w-full`}
                                                    name={`attendeesDetails.${index}.name`}
                                                    placeholder='Employee Name'
                                                    value={attendee.name}
                                                    required
                                                    onChange={(e) => handleAttendeeChange(index, 'name', e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup className="customFormGroup">
                                                <FormLabel className="customFormLabel">Employee ID</FormLabel>
                                                <TextField
                                                    type='text'
                                                    className={`custumTextField profileInput w-full`}
                                                    name={`attendeesDetails.${index}.id`}
                                                    placeholder='Employee ID'
                                                    value={attendee.id}
                                                    onChange={(e) => handleAttendeeChange(index, 'id', e.target.value)}
                                                />
                                            </FormGroup>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <button type='button' className={styles.AddMoreBtn} onClick={handleAddMore}>
                                    <AddMoreIcon />
                                </button>
                            </div>
                        </div>
                        <button className='fillButton mt-8'>{btnLoading && <BtnLoader />} Save Details</button>
                    </form>
                </div>
            </div >
        </CustomContainer >
    )
}

export default AddTraining