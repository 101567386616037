import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import CustomContainer from '../../components/common/CustomContainer';
import { BackIcon } from '../../utils/functions/Icons';
import { useNavigate } from 'react-router-dom';
import styles from "../../assets/styles/profile.module.css";
import { FormGroup, FormLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextareaAutosize, TextField } from '@mui/material';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { toast } from 'react-toastify';
import BtnLoader from '../../components/common/BtnLoader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { EquipmentItemsReducer, firstAidItemsReducer } from '../../utils/store/profile/ProfileSlice';
import NoDataFound from '../../components/common/NoDataFound';
import { addEquipmentApi, addFirstAidAddApi } from '../../utils/api/services';
import { handleKeyPress } from '../../utils/functions/common';

interface EquipmentDetail {
    itemName: string;
    department: string;
    floor: string;
    addTotal: string;
    utilization: string;
    opening: string;
    closing: string;
}

interface FormState {
    updatedBy: string;
    date: Date | null | string;
    equipmentDetails: EquipmentDetail[];
}

const AddFirstAid = () => {
    const [formState, setFormState] = useState<FormState>({
        updatedBy: '',
        date: null,
        equipmentDetails: []
    });
    const [btnLoading, setBtnLoading] = useState(false);
    const { LoginData } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { master } = useSelector((state: RootState) => state.profile);

    useEffect(() => {
        dispatch(firstAidItemsReducer({ path: 'firstAidItems' }));
    }, [dispatch]);

    useEffect(() => {
        if (master?.firstAidItems) {
            const initialDetails = master?.firstAidItems?.map(item => ({
                itemName: item.itemName,
                department: item?.department,
                floor: item?.floor,
                addTotal: '',
                utilization: '',
                opening: '',
                closing: '',
            }));
            setFormState(prevState => ({
                ...prevState,
                equipmentDetails: initialDetails
            }));
        }
    }, [master]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { name, value } = e.target;
        const updatedDetails = [...formState.equipmentDetails];
        updatedDetails[index] = {
            ...updatedDetails[index],
            [name]: value
        };
        setFormState({
            ...formState,
            equipmentDetails: updatedDetails
        });
    };

    const handleDateChange = (value: Date | null) => {
        const dateValue = moment(value)?.format('DD-MM-YYYY')
        setFormState({
            ...formState,
            date: dateValue
        });
    };
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const dataToSubmit = {
            firstAid: {
                updatedBy: formState.updatedBy,
                date: formState.date,
            },
            firstAidDetails: formState.equipmentDetails.map(item => ({
                itemName: item.itemName,
                department: item?.department,
                floor: item?.floor,
                addTotal: item?.addTotal,
                utilization: item?.utilization,
                opening: item?.opening,
                closing: item?.closing,
            }))
        };
        setBtnLoading(true);
        try {
            const result = await addFirstAidAddApi(dataToSubmit);
            if (result?.success) {
                toast.success(result?.message);
                navigate('/firstaid');
            } else {
                toast.error(result?.message);
            }
        } catch (error: any) {
            setBtnLoading(false);
            toast.error(error?.response?.data?.message || error.message);
        } finally {
            setBtnLoading(false);
        }
    };

    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        First Aid check list
                    </h2>
                </div>
                <div className={`${styles.profileMain} mt-8`}>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Date <span className='errorMsg'>*</span></FormLabel>
                                <DatePicker
                                    className="custumTextField profileInput"
                                    name='date'
                                    value={formState?.date ? moment(formState?.date, 'DD-MM-YYYY') : null}
                                    format="DD-MM-YYYY"
                                    onChange={(date) => handleDateChange(moment(date)?.toDate())}

                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Updated by <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="updatedBy"
                                    placeholder='Updated by'
                                    value={formState.updatedBy}
                                    onChange={(e) => setFormState({ ...formState, updatedBy: e.target.value })}
                                    required
                                />
                            </FormGroup>
                        </div>
                        <div className='mt-8'>
                            <TableContainer component={Paper} className='TableContainer'>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead className='custumTableHead'>
                                        <TableRow>
                                            <TableCell className='whitespace-nowrap'>Sr No.</TableCell>
                                            <TableCell className='whitespace-nowrap'>First-aid kit items</TableCell>
                                            <TableCell className='whitespace-nowrap'>Department</TableCell>
                                            <TableCell className='whitespace-nowrap'>Floor</TableCell>
                                            <TableCell className='whitespace-nowrap'>Opening</TableCell>
                                            <TableCell className='whitespace-nowrap'>New add</TableCell>
                                            <TableCell className='whitespace-nowrap'>Utilization</TableCell>
                                            <TableCell className='whitespace-nowrap'>Closing</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {master?.firstAidItems?.length > 0 ? (
                                        <TableBody>
                                            {master.firstAidItems.map((item, index) => (
                                                <TableRow
                                                    key={index}
                                                    className='custumTableRow'
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="td" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        {item?.itemName}
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        {item?.department}
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        {item?.floor}
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        <TextField
                                                            type='text'
                                                            className={`custumTextField ${styles.InputStyle}`}
                                                            name="opening"
                                                            value={formState.equipmentDetails[index]?.opening || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                            }}
                                                            onKeyPress={handleKeyPress}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        <TextField
                                                            type='text'
                                                            className={`custumTextField ${styles.InputStyle}`}
                                                            name="addTotal"
                                                            value={formState.equipmentDetails[index]?.addTotal || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                            }}
                                                            onKeyPress={handleKeyPress}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        <TextField
                                                            type='text'
                                                            className={`custumTextField ${styles.InputStyle}`}
                                                            name="utilization"
                                                            value={formState.equipmentDetails[index]?.utilization || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                            }}
                                                            onKeyPress={handleKeyPress}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        <TextField
                                                            type='text'
                                                            className={`custumTextField ${styles.InputStyle}`}
                                                            name="closing"
                                                            value={formState.equipmentDetails[index]?.closing || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                            }}
                                                            onKeyPress={handleKeyPress}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={7} align="center">
                                                    <NoDataFound />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </div>
                        <button className='fillButton mt-8'> {btnLoading && <BtnLoader />} Save Details</button>
                    </form>
                </div>
            </div>
        </CustomContainer>
    );
};

export default AddFirstAid;
