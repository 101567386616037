import React, { FormEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { BackIcon } from '../../utils/functions/Icons'
import { useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/profile.module.css"
import { FormGroup, FormLabel, TextField } from '@mui/material'
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers'
import { toast } from 'react-toastify'
import { addBmwListApi } from '../../utils/api/services'
import BtnLoader from '../../components/common/BtnLoader'
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
import { handleKeyPress } from '../../utils/functions/common'

const AddBMW = () => {
    const [FormState, setFormState] = useState<AddBMWTypes>({
        vendorName: '',
        date: null,
        onDutyNurse: '',
        weightWhiteBag: '',
        weightBlueBag: '',
        weightRedBag: '',
        weightYellowBag: '',
        totalWeight: '',
    })
    const [btnLoading, setBtnLoading] = useState(false)
    const { LoginData } = useSelector((state: RootState) => state.auth)

    const navigate = useNavigate();

    useEffect(() => {
        if (FormState?.weightWhiteBag && FormState?.weightBlueBag && FormState?.weightRedBag && FormState?.weightYellowBag) {
            setFormState({
                ...FormState,
                totalWeight: (Number(FormState?.weightWhiteBag) + Number(FormState?.weightBlueBag) + Number(FormState?.weightRedBag) + Number(FormState?.weightYellowBag)).toString()
            })
        }
    }, [FormState])

    const handleInputChange: HandleInputChangeType = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...FormState,
            [name]: value
        })
    };
    const handleDateChange = (value: Date | null, name: string) => {
        const DateValue = moment(value)?.format('DD-MM-YYYY')
        setFormState({
            ...FormState,
            [name]: DateValue
        })
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (!FormState?.date) {
                return toast.error('Date is required')
            }
            const sendData = {
                companyId: LoginData?.companyId,
                vendorName: FormState?.vendorName,
                date: FormState?.date,
                onDutyNurse: FormState?.onDutyNurse,
                weightWhiteBag: FormState?.weightWhiteBag,
                weightBlueBag: FormState?.weightBlueBag,
                weightRedBag: FormState?.weightRedBag,
                weightYellowBag: FormState?.weightYellowBag,
                totalWeight: FormState?.totalWeight,
            }
            setBtnLoading(true)
            const result = await addBmwListApi(sendData);
            if (result?.success) {
                toast.success(result?.message)
                setBtnLoading(false)
                navigate('/bioMedWaste')
            }
            else {
                setBtnLoading(false)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        Bio Medical Waste
                    </h2>
                </div>
                <div className={`${styles.profileMain} mt-8`}>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-4 gap-x-16 gap-y-6">
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Vendor Name <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="vendorName"
                                    placeholder='Vendor Name'
                                    value={FormState?.vendorName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Date <span className='errorMsg'>*</span></FormLabel>
                                <DatePicker
                                    className="custumTextField profileInput"
                                    name='date'
                                    value={FormState?.date ? moment(FormState?.date, 'DD-MM-YYYY') : null}
                                    // maxDate={moment(new Date(), 'DD-MM-YYYY')}
                                    format="DD-MM-YYYY"
                                    onChange={(date) => handleDateChange(moment(date)?.toDate(), 'date')}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">On duty nurse <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="onDutyNurse"
                                    placeholder='On duty nurse'
                                    value={FormState?.onDutyNurse}
                                    onChange={handleInputChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Weight - White Bag (gm) <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="weightWhiteBag"
                                    placeholder='Weight - White Bag (gm)'
                                    value={FormState?.weightWhiteBag}
                                    onChange={handleInputChange}
                                    required
                                    inputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Weight - Blue Bag (gm) <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="weightBlueBag"
                                    placeholder='Weight - Blue Bag (gm)'
                                    value={FormState?.weightBlueBag}
                                    onChange={handleInputChange}
                                    required
                                    inputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Weight - Yellow Bag (gm) <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="weightYellowBag"
                                    placeholder='Weight - Yellow Bag (gm)'
                                    value={FormState?.weightYellowBag}
                                    onChange={handleInputChange}
                                    required
                                    inputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Weight - Red Bag (gm) <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="weightRedBag"
                                    placeholder='Weight - Red Bag (gm)'
                                    value={FormState?.weightRedBag}
                                    onChange={handleInputChange}
                                    required
                                    inputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Total Weight (gm) <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="totalWeight"
                                    placeholder='Total Weight (gm)'
                                    value={FormState?.totalWeight}
                                    disabled
                                    onChange={handleInputChange}
                                    inputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                        </div>
                        <button className='fillButton mt-8'> {btnLoading && <BtnLoader />} Save Details</button>
                    </form>
                </div>
            </div >
        </CustomContainer >
    )
}

export default AddBMW