import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { AddMoreIcon, BackIcon } from '../../utils/functions/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/profile.module.css"
import { FormControlLabel, FormGroup, FormLabel, MenuItem, Radio, RadioGroup, Select, TextareaAutosize, TextField } from '@mui/material'
import { addHandoverApi, addInventoryApi, editHandoverApi, editInventoryApi, getHandoverDetailsApi, getInventoryDetailsApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import moment from 'moment'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { LaundaryItemsReducer, MedicineReducer } from '../../utils/store/profile/ProfileSlice'
import BtnLoader from '../../components/common/BtnLoader'
import Loader from '../../components/common/Loader'
import AutocompleteInput from '../../components/common/AutocompleteInput'
import { handleKeyPress, parseData } from '../../utils/functions/common'

interface InvformStates {
    medicineName: string[];
    batchNumber: string;
    openingStock: string;
    expiryDate: Date | string;
    receivedStock: string;
    discard: string;
    closingStock: string;
    transferFirstAid: string;
    remarks: string;
}
const AddInventory = () => {

    const [formStates, setFormStates] = useState<InvformStates>({
        medicineName: [],
        batchNumber: '',
        openingStock: '',
        expiryDate: '',
        receivedStock: '',
        discard: '',
        closingStock: '',
        transferFirstAid: '',
        remarks: '',
    })
    const [btnLoading, setBtnLoading] = useState(false)
    const [loading, setLoading] = useState(false);
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const { master } = useSelector((state: RootState) => state.profile)
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dispatch(MedicineReducer({ path: 'medicines' }))
    }, [])

    const fetchInventoryDetails = async (id: number) => {
        try {
            setLoading(true)
            const result = await getInventoryDetailsApi(id)
            if (result?.success) {
                setLoading(false)
                const inventoryData = result?.inventories as InventoryDetailsTypes;
                if (inventoryData) {
                    setFormStates({
                        ...formStates,
                        medicineName: parseData(inventoryData?.medicineName),
                        batchNumber: inventoryData?.batchNumber,
                        openingStock: inventoryData?.openingStock,
                        expiryDate: inventoryData?.expiryDate,
                        receivedStock: inventoryData?.receivedStock,
                        discard: inventoryData?.discard,
                        closingStock: inventoryData?.closingStock,
                        transferFirstAid: inventoryData?.transferFirstAid,
                        remarks: inventoryData?.remarks
                    })
                }
            }
            else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.response?.data?.message || error.message);

        }
    }
    useEffect(() => {
        if (location?.state?.id) {
            setIsEdit(true)
            fetchInventoryDetails(location?.state?.id);
        }
    }, []);


    const handleDateChange = (value: Date | null, name: string) => {
        const DateValue = moment(value)?.format('DD-MM-YYYY');
        setFormStates({
            ...formStates,
            expiryDate: DateValue,
        });
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormStates({
            ...formStates,
            [name]: value
        })
    }
    const handleAutoComplete = (name: any, value: any,) => {
        setFormStates((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    };
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const sendData = {
                medicineName: JSON.stringify(formStates?.medicineName?.map((item: any) => item?.medicineName ? item?.medicineName : item)),
                batchNumber: formStates?.batchNumber,
                openingStock: formStates?.openingStock,
                expiryDate: formStates?.expiryDate,
                receivedStock: formStates?.receivedStock,
                closingStock: formStates?.closingStock,
                transferFirstAid: formStates?.transferFirstAid,
                remarks: formStates?.remarks,
                companyId: LoginData?.companyId,
            };
            const editSendData = {
                inventoryId: location?.state?.id,
                discard: formStates?.discard
            }
            setBtnLoading(true)
            const result = isEdit ? await editInventoryApi(editSendData) : await addInventoryApi(sendData);
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                navigate('/inventory')
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        Inventory
                    </h2>
                </div>
                <div className={styles.profileMain}>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-3 gap-x-16 gap-y-6 mt-8">
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Medicine Name <span className='errorMsgInput'>*</span> </FormLabel>
                                <AutocompleteInput
                                    options={master?.medicines}
                                    multiple={true}
                                    disabled={isEdit}
                                    className="custumTextField profileInput"
                                    value={formStates?.medicineName}
                                    getOptionLabel={(option) => typeof option === 'string' ? option : option.medicineName}
                                    getOptionKey={(option) => typeof option === 'string' ? option : option.medicineName}
                                    createOption={(inputValue: string) => {
                                        return { medicineName: inputValue };
                                    }}
                                    onSelect={(value) => handleAutoComplete('medicineName', value)}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Batch Number <span className='errorMsgInput'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="batchNumber"
                                    placeholder='Batch Number'
                                    disabled={isEdit}
                                    value={formStates?.batchNumber}
                                    onChange={handleInputChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Opening Stock for month <span className='errorMsgInput'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="openingStock"
                                    placeholder='Opening Stock for month'
                                    value={formStates?.openingStock}
                                    onChange={handleInputChange}
                                    required
                                    disabled={isEdit}
                                    inputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Expiry Date <span className='errorMsgInput'>*</span> </FormLabel>
                                <DatePicker
                                    className="custumTextField profileInput"
                                    name={'expiryDate'}
                                    minDate={moment(new Date(), 'DD-MM-YYYY')}
                                    format="DD-MM-YYYY"
                                    disabled={isEdit}
                                    value={formStates?.expiryDate ? moment(formStates?.expiryDate, 'DD-MM-YYYY') : null}
                                    onChange={(date) => handleDateChange(moment(date)?.toDate(), 'date')}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Received stock this month <span className='errorMsgInput'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="receivedStock"
                                    placeholder='Received stock this month'
                                    value={formStates?.receivedStock}
                                    onChange={handleInputChange}
                                    required
                                    disabled={isEdit}
                                    inputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            {
                                isEdit &&
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Discard</FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput w-full`}
                                        name="discard"
                                        placeholder='Discard'
                                        value={formStates?.discard}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            }
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Closing Stock <span className='errorMsgInput'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="closingStock"
                                    placeholder='Closing Stock'
                                    value={formStates?.closingStock}
                                    onChange={handleInputChange}
                                    required
                                    disabled={isEdit}
                                    inputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Transfer to first aid <span className='errorMsgInput'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="transferFirstAid"
                                    placeholder='Transfer to first aid'
                                    value={formStates?.transferFirstAid}
                                    onChange={handleInputChange}
                                    required
                                    disabled={isEdit}
                                    inputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup col-span-3" >
                                <FormLabel className="customFormLabel">Remarks</FormLabel>
                                <TextareaAutosize
                                    minRows={2}
                                    className={`custumTextField profileInput`}
                                    name='remarks'
                                    placeholder='Remarks'
                                    disabled={isEdit}
                                    value={formStates?.remarks}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </div>
                        <button type='submit' className='fillButton mt-8'> {btnLoading && <BtnLoader />} Save Details</button>
                    </form>
                </div>
            </div>
        </CustomContainer>
    )
}

export default AddInventory