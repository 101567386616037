export const BASE_URL = "https://ohcapi.akosmd.in/api"; //development
// export const PATIENT_PORTAL = "http://localhost:3005"
export const PATIENT_PORTAL = "https://360.akosmd.in/"

export const LOGIN = "/auth/login";
export const FORGOT_PASSWORD = "/forgot-password/send-otp";
export const VERIFY_OTP = "/forgot-password/verify-otp";
export const CHANGE_PASSWORD = "/forgot-password/change-password";
export const CHANGE_PASSWORD_INSIDE = "/forgot-password/change_password";
export const UPLOAD_FILE = "/data/upload";
export const GET_MASTER = "/data/list";
export const GET_STATE = "/data/states";
export const GET_CITY = "/data/cities";
export const GET_NOTIFICATION = "/notify/notifications";
export const REMOVE_NOTIFICATION = "/notify/removeNotification";
export const GET_MEDICALROOM = "/medicalRooms/checklist";
export const TRAINING_LIST = "/training/list";
export const ADD_TRAINING_LIST = "/training/create";
export const BMW_LIST = "/bmw/list";
export const BMW_CREATE = "/bmw/create";
export const LAUNDARY_LIST = "/laundary/list";
export const CREATE_LAUNDRY = "/laundary/create";
export const EQUIPMENT_LIST = "/equipment/list";
export const EQUIPMENT_CREATE = "/equipment/create";
export const HANDOVER_LIST = "/handover/list";
export const HANDOVER_DETAILS = "/handover/detail";
export const HANDOVER_CREATE = "/handover/create";
export const HANDOVER_EDIT = "/handover/edit";
export const INVENTORY_LIST = "/inventory/list";
export const INVENTORY_CREATE = "/inventory/create";
export const INVENTORY_EDIT = "/inventory/edit";
export const INVENTORY_DETAILS = "/inventory/detail";
export const STERLIZATION_LIST = "/sterlization/list";
export const STERLIZATION_CREATE = "/sterlization/create";
export const AMBULANCE_CHECKLIST = "/ambulance/list";
export const AMBULANCE_CHECKLIST_CREATE = "/ambulance/create";
export const AMBULANCE_UTILIZATION_LIST = "/ambulance-utilization/list";
export const AMBULANCE_UTILIZATION_CREATE = "/ambulance-utilization/create";
export const FIRST_AID_LIST = "/firstAid/list";
export const FIRST_AID_CREATE = "/firstAid/create";
export const GET_DASHBOARD = "/dashboard/list";
export const GET_PROFILE = "/user/detail";
export const USER_EDIT = "/user/edit";
export const MEDICAL_ROOMS_DETAILS = "/medicalRooms/detail";
export const RESEND_OTP = "/forgot-password/resend-otp";
export const OPD_CREATE = "/opd/create";
export const OPD_LIST = "/opd/list";
