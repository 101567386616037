import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/labtest.module.css"
import MedicalRoomTable from '../../components/medicalRooms/MedicalRoomTable'
import { DownloadTableExcel, useDownloadExcel } from 'react-export-table-to-excel';
import TrainingTable from '../../components/training/TrainingTable'
import { getTraningApi } from '../../utils/api/services'
import { SelectChangeEvent } from '@mui/material'
import { toast } from 'react-toastify'
import moment, { Moment } from 'moment'

const Training = () => {
    const [searchValue, setSearchValue] = useState('')
    const [filterValue, setFilterValue] = useState<FilterTypes>({
        fromDate: null,
        toDate: null
    })
    const [loading, setLoading] = useState(false)
    const [trainingData, setTrainingData] = useState<TrainingTypes[] | []>([])
    const navigate = useNavigate();
    const tableRef = useRef<HTMLTableElement>(null);
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Training',
        sheet: 'list'
    })

    const handleDateChange = (value: Date | null, name: string) => {
        const momentValue = value !== null ? moment(value)?.format('DD-MM-YYYY') : null;
        setFilterValue({
            ...filterValue,
            [name]: momentValue
        })
    }
    const handleSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }
    const fetchTrainingData = async (data?: { searchQuery?: string, fromDate?: Date | null, toDate: Date | null }) => {
        try {
            setLoading(true)
            const result = await getTraningApi(data);
            if (result?.success) {
                setLoading(false)
                setTrainingData(result?.trainings)
            }
            else {
                setLoading(false)
                setTrainingData([])
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        if (searchValue) {
            const cleanUpFun = setTimeout(() => {
                fetchTrainingData({ searchQuery: searchValue, fromDate: filterValue?.fromDate, toDate: filterValue?.toDate})
            }, 1000);
            return () => clearInterval(cleanUpFun)
        }
        else {
            fetchTrainingData({ searchQuery: searchValue ? searchValue : undefined, fromDate: filterValue?.fromDate, toDate: filterValue?.toDate })
        }
    }, [searchValue, filterValue]);

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>Training</h2>
                    <div className='flex items-center gap-6'>
                        <button className='fillButton' onClick={() => navigate('/training/add')}>New Entry +</button>
                        <button className='outLineBtn' onClick={onDownload}>Export Data</button>
                    </div>
                </div>
                <div className={styles.labTestMain}>
                    <TrainingTable
                        trainingData={trainingData}
                        loading={loading}
                        searchValue={searchValue}
                        ref={tableRef}
                        handleSearchValue={handleSearchValue}
                        filterValue={filterValue}
                        handleDateChange={handleDateChange}
                    />
                </div>
            </div>
        </CustomContainer>
    )
}

export default Training