import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import Cookies from "js-cookie";
import store from "../store";
import { clearAuth, setAuthToken } from "../store/auth/AuthSlice";
import { BASE_URL } from "./routes";

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
const axiosWithAuth = axios.create();

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

axiosWithAuth.interceptors.request.use(
  async (config: CustomAxiosRequestConfig) => {
    const token = Cookies.get("token");
    const _loginData = localStorage.getItem("patientInfo");
    const parsedLoginData = _loginData ? JSON.parse(_loginData) : null;
    if (token) {
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${token}`;
      if (parsedLoginData?.companyId) {
        config.headers["companyId"] = parsedLoginData?.companyId;
      }
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

axiosWithAuth.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & {
      _retry?: boolean;
    };
    if (
      error.response?.status === 401 &&
      originalRequest &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const refreshToken = Cookies.get("refreshToken");
        const { data } = await axiosWithAuth.post("/refresh-token", {
          refreshToken,
        });
        const newToken = data.token;

        Cookies.set("token", newToken);
        store.dispatch(setAuthToken(newToken));
        axiosWithAuth.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newToken}`;
        if (originalRequest.headers) {
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        }
        return axiosWithAuth(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        Cookies.remove("token");
        Cookies.remove("refreshToken");
        store.dispatch(clearAuth());
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosWithAuth;
