import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./auth/AuthSlice";
import CommonSlice from "./common/CommonSlice";
import ProfileSlice from "./profile/ProfileSlice";

const AllReducers = combineReducers({
  auth: AuthSlice.reducer,
  common: CommonSlice.reducer,
  profile: ProfileSlice.reducer,
});

export default AllReducers;
