import React, { FormEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { BackIcon } from '../../utils/functions/Icons'
import { useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/profile.module.css"
import { FormGroup, FormLabel, TextareaAutosize, TextField } from '@mui/material'
import moment from 'moment'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { toast } from 'react-toastify'
import { addAmbulanceUtilizationApi, addBmwListApi, addSterlizationApi } from '../../utils/api/services'
import BtnLoader from '../../components/common/BtnLoader'
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
import { handleKeyPress } from '../../utils/functions/common'

const AddAmbulanceUtilization = () => {
    const [FormState, setFormState] = useState<AmbUtilizationFormTypes>({
        callReceivedTime: '',
        locationIncidentSpot: '',
        incidentDate: '',
        callReceivedFrom: '',
        callReceivedFromNumber: '',
        pickupIncidentTime: '',
        pickupIncidentDate: '',
        patientName: '',
        employeeId: '',
        spotToHospitalTime: '',
        startReading: '',
        endReading: '',
        dropTimeNearestHospital: '',
        hospitalName: '',
        reportingBackTime: '',
        driverName: '',
        SPOCName: '',
        otherDetails: ''
    })
    const [btnLoading, setBtnLoading] = useState(false)
    const { LoginData } = useSelector((state: RootState) => state.auth)

    const navigate = useNavigate();

    const handleInputChange: HandleInputChangeType = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...FormState,
            [name]: value
        })
    };
    const handleDateChange = (value: Date | null, name: string) => {
        const DateValue = moment(value)?.format('DD-MM-YYYY')
        setFormState({
            ...FormState,
            [name]: DateValue
        })
    };
    const handleTimeChange = (value: Date | null, name: string) => {
        const TimeValue = moment(value)?.format('HH:mm')
        setFormState({
            ...FormState,
            [name]: TimeValue
        })
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const sendData = {
                callReceivedTime: FormState.callReceivedTime,
                locationIncidentSpot: FormState.locationIncidentSpot,
                incidentDate: FormState.incidentDate,
                callReceivedFrom: FormState.callReceivedFrom,
                callReceivedFromNumber: FormState.callReceivedFromNumber,
                pickupIncidentTime: FormState.pickupIncidentTime,
                pickupIncidentDate: FormState.pickupIncidentDate,
                patientName: FormState.patientName,
                employeeId: FormState.employeeId,
                spotToHospitalTime: FormState.spotToHospitalTime,
                startReading: FormState.startReading,
                endReading: FormState.endReading,
                dropTimeNearestHospital: FormState.dropTimeNearestHospital,
                hospitalName: FormState.hospitalName,
                reportingBackTime: FormState.reportingBackTime,
                driverName: FormState.driverName,
                SPOCName: FormState.SPOCName,
                otherDetails: FormState.otherDetails
            }
            setBtnLoading(true)
            const result = await addAmbulanceUtilizationApi(sendData);
            if (result?.success) {
                toast.success(result?.message)
                setBtnLoading(false)
                navigate('/ambulance/utilization')
            }
            else {
                setBtnLoading(false)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        Ambulance Utilization
                    </h2>
                </div>
                <div className={`${styles.profileMain} mt-8`}>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-3 gap-x-16 gap-y-6">
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Call received (Timing) <span className='errorMsg'>*</span></FormLabel>
                                <TimePicker
                                    className="custumTextField profileInput"
                                    name={'callReceivedTime'}
                                    value={FormState?.callReceivedTime ? moment(FormState?.callReceivedTime, 'HH:mm') : null}
                                    onChange={(date) => handleTimeChange(moment(date)?.toDate(), 'callReceivedTime')}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Location of Incident Spot <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="locationIncidentSpot"
                                    placeholder='Location Incident Spot'
                                    value={FormState?.locationIncidentSpot}
                                    onChange={handleInputChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Incident Date <span className='errorMsg'>*</span></FormLabel>
                                <DatePicker
                                    className="custumTextField profileInput"
                                    name='incidentDate'
                                    value={FormState?.incidentDate ? moment(FormState?.incidentDate, 'DD-MM-YYYY') : null}
                                    format="DD-MM-YYYY"
                                    onChange={(date) => handleDateChange(moment(date)?.toDate(), 'incidentDate')}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Call Received From <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="callReceivedFrom"
                                    placeholder='Call Received From'
                                    value={FormState?.callReceivedFrom}
                                    onChange={handleInputChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Call Received From Number <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="callReceivedFromNumber"
                                    placeholder='Call Received From Number'
                                    value={FormState?.callReceivedFromNumber}
                                    onChange={handleInputChange}
                                    required
                                    inputProps={{
                                        inputMode: 'numeric',
                                        maxLength: 10

                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Pickup Incident Time <span className='errorMsg'>*</span></FormLabel>
                                <TimePicker
                                    className="custumTextField profileInput"
                                    name={'pickupIncidentTime'}
                                    value={FormState?.pickupIncidentTime ? moment(FormState?.pickupIncidentTime, 'HH:mm') : null}
                                    onChange={(date) => handleTimeChange(moment(date)?.toDate(), 'pickupIncidentTime')}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Pickup Incident Date <span className='errorMsg'>*</span></FormLabel>
                                <DatePicker
                                    className="custumTextField profileInput"
                                    name='pickupIncidentDate'
                                    value={FormState?.pickupIncidentDate ? moment(FormState?.pickupIncidentDate, 'DD-MM-YYYY') : null}
                                    format="DD-MM-YYYY"
                                    onChange={(date) => handleDateChange(moment(date)?.toDate(), 'pickupIncidentDate')}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Patient Name <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="patientName"
                                    placeholder='Patient Name'
                                    value={FormState?.patientName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Employee ID</FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="employeeId"
                                    placeholder='Employee ID'
                                    value={FormState?.employeeId}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Spot to Hospital Time <span className='errorMsg'>*</span></FormLabel>
                                <TimePicker
                                    className="custumTextField profileInput"
                                    name={'spotToHospitalTime'}
                                    value={FormState?.spotToHospitalTime ? moment(FormState?.spotToHospitalTime, 'HH:mm') : null}
                                    onChange={(date) => handleTimeChange(moment(date)?.toDate(), 'spotToHospitalTime')}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Start KM reading <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    className="custumTextField profileInput"
                                    name={'startReading'}
                                    value={FormState?.startReading}
                                    onChange={handleInputChange}
                                    required
                                    inputProps={{
                                        inputMode: 'numeric',

                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">End KM reading <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    className="custumTextField profileInput"
                                    name={'endReading'}
                                    value={FormState?.endReading}
                                    onChange={handleInputChange}
                                    required
                                    inputProps={{
                                        inputMode: 'numeric',

                                    }}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Drop Time Nearest Hospital <span className='errorMsg'>*</span></FormLabel>
                                <TimePicker
                                    className="custumTextField profileInput"
                                    name={'dropTimeNearestHospital'}
                                    value={FormState?.dropTimeNearestHospital ? moment(FormState?.dropTimeNearestHospital, 'HH:mm') : null}
                                    onChange={(date) => handleTimeChange(moment(date)?.toDate(), 'dropTimeNearestHospital')}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Hospital Name <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="hospitalName"
                                    placeholder='Hospital Name'
                                    value={FormState?.hospitalName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Reporting Back Time <span className='errorMsg'>*</span></FormLabel>
                                <TimePicker
                                    className="custumTextField profileInput"
                                    name={'reportingBackTime'}
                                    value={FormState?.reportingBackTime ? moment(FormState?.reportingBackTime, 'HH:mm') : null}
                                    onChange={(date) => handleTimeChange(moment(date)?.toDate(), 'reportingBackTime')}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Driver Name <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="driverName"
                                    placeholder='Driver Name'
                                    value={FormState?.driverName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">SPOC Name <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="SPOCName"
                                    placeholder='SPOC Name'
                                    value={FormState?.SPOCName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup col-span-3">
                                <FormLabel className="customFormLabel">Other Details </FormLabel>
                                <TextareaAutosize
                                    minRows={2}
                                    className={`custumTextField profileInput`}
                                    name='otherDetails'
                                    placeholder='Other Details'
                                    value={FormState?.otherDetails}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </div>
                        <button className='fillButton mt-8'> {btnLoading && <BtnLoader />} Save Details</button>
                    </form>
                </div>
            </div >
        </CustomContainer >
    )
}

export default AddAmbulanceUtilization