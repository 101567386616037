import React, { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/dashboard.module.css"
import { DashboardArrowIcon } from '../../utils/functions/Icons'
import { toast } from 'react-toastify'
import Loader from '../../components/common/Loader'
import { useNavigate } from 'react-router-dom'
import { getDashboardApi } from '../../utils/api/services'

const Dashboard = () => {
    const [loading, setLoading] = useState(false)
    const [dashboardData, setDashboardData] = useState<DashboardDataTypes | null>(null)
    const navigate = useNavigate();
    const fetchDashboardData = async () => {
        try {
            setLoading(true)
            const result = await getDashboardApi();
            if (result?.success) {
                setDashboardData(result?.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        fetchDashboardData();
    }, [])

    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>Dashboard</h2>
                </div>
                <div className={styles.dashboardMain}>
                    <div className='grid grid-cols-3 gap-6'>
                        {
                            dashboardData?.medicalRoom &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/medicalRooms')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>Medical Rooms</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        <div className={styles.contentData}>
                                            <h4>Last updated on</h4>
                                            <p>{dashboardData?.medicalRoom?.updatedAt}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>On duty Staff</h4>
                                            <p>{dashboardData?.medicalRoom?.staffName}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Last caliberation</h4>
                                            <p>{dashboardData?.medicalRoom?.date}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.equipment &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/equipment')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>Equipment</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        <div className={styles.contentData}>
                                            <h4>Date</h4>
                                            <p>{dashboardData?.equipment?.date}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Newly added</h4>
                                            <p>{dashboardData?.equipment?.newlyAddedSum}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Discarded</h4>
                                            <p>{dashboardData?.equipment?.discardedSum}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Total Quantity</h4>
                                            <p>{dashboardData?.equipment?.totalQuantitySum}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.handover &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/handover')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>Handover</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        <div className={styles.contentData}>
                                            <h4>Handover By</h4>
                                            <p>{dashboardData?.handover?.handoverBy || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Taken By</h4>
                                            <p>{dashboardData?.handover?.takenBy || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Accepting Handover</h4>
                                            <p>{dashboardData?.handover?.acceptingHandover || '-'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Handover Duty Time</h4>
                                            <p>{dashboardData?.handover?.handoverDutyTime || '_'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.laundary &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/laundry')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>Laundry</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        <div className={styles.contentData}>
                                            <h4>Date</h4>
                                            <p>{dashboardData?.laundary?.dateSent || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>No. of items sent</h4>
                                            <p>{dashboardData?.laundary?.quantitySent || '_'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.ambulanceChecklist &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/ambulance/checklist')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>Ambulance checklist</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        <div className={styles.contentData}>
                                            <h4>Date</h4>
                                            <p>{dashboardData?.ambulanceChecklist?.date || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Last checked by</h4>
                                            <p>{dashboardData?.ambulanceChecklist?.onDutyEmployee || '_'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.ambulanceUtilization &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/ambulance/utilization')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>Ambulance Utilization</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        <div className={styles.contentData}>
                                            <h4>Hospital Name</h4>
                                            <p>{dashboardData?.ambulanceUtilization?.hospitalName || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Date & Time</h4>
                                            <p>{`${dashboardData?.ambulanceUtilization?.incidentDate}  ${dashboardData?.ambulanceUtilization?.pickupIncidentTime}` || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Driver Name</h4>
                                            <p>{dashboardData?.ambulanceUtilization?.driverName || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>SPOC Name</h4>
                                            <p>{dashboardData?.ambulanceUtilization?.SPOCName || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Location of incident</h4>
                                            <p>{dashboardData?.ambulanceUtilization?.locationIncidentSpot || '_'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.bmw &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/bioMedWaste')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>Bio Medical Waste</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        <div className={styles.contentData}>
                                            <h4>Date</h4>
                                            <p>{dashboardData?.bmw?.date || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Total waste collected (gm)</h4>
                                            <p>{dashboardData?.bmw?.totalWeight || '_'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.training &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/training')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>Training</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        <div className={styles.contentData}>
                                            <h4>Date</h4>
                                            <p>{dashboardData?.training?.dateOfTraining || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Trainer Name</h4>
                                            <p>{dashboardData?.training?.trainerName || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Training Topics</h4>
                                            <p>{dashboardData?.training?.trainingTopics || '_'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.opdCount && dashboardData?.opdCount?.length > 0 &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/opd')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>OPD</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        {
                                            dashboardData?.opdCount?.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className={styles.contentData}>
                                                            <h4>Date</h4>
                                                            <p>{item?.date || '_'}</p>
                                                        </div>
                                                        <div className={styles.contentData}>
                                                            <h4>Count</h4>
                                                            <p>{item?.count || '_'}</p>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.sterlization &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/sterlization')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>Sterlization</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        <div className={styles.contentData}>
                                            <h4>Date</h4>
                                            <p>{dashboardData?.sterlization?.date || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>On Duty Staff</h4>
                                            <p>{dashboardData?.sterlization?.onDutyStaff || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Sterlized Equipment</h4>
                                            <p>{dashboardData?.sterlization?.sterlizedEquipment || '_'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.inventory &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/inventory')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>Inventory</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        <div className={styles.contentData}>
                                            <h4>Updated At</h4>
                                            <p>{dashboardData?.inventory?.updatedAt || '_'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.firstAid &&
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/firstaid')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHead}>
                                        <h3>First Aid</h3>
                                    </div>
                                    <div className={styles.ContentMain}>
                                        <div className={styles.contentData}>
                                            <h4>Updated by</h4>
                                            <p>{dashboardData?.firstAid?.updatedBy || '_'}</p>
                                        </div>
                                        <div className={styles.contentData}>
                                            <h4>Updated date</h4>
                                            <p>{dashboardData?.firstAid?.date || '_'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </CustomContainer>
    )
}

export default Dashboard